import React, {useContext} from 'react';

import {CONTACT_US_ROUTE} from "../../library/Navigation/NavigationConstants";
import {LocalisationContext} from "../../library/Providers/SharedProviders/LocalisationProvider";

import ContentSizeLimiter from "../../components/UI/Containers/ContentSizeLimiter";
import StaticPageContainerWithHeaderSpaceFix
	from "../../components/Layout/StaticPageContainerWithHeaderSpaceFix";
import DefaultButton from "../../components/UI/Buttons/DefaultButton";
import SectionWithSideImage from "../../module/StaticPages/components/Layout/Sections/SectionWithSideImage";

import HeroSection from "./components/HeroSection";
import PosPresentationSection from "./components/PosPresentationSection";
import BenefitsSection from "./components/BenefitsSection";
import ContentForSectionWithSideImage from "./components/ContentForSectionWithSideImage";
import earnMoreImage from './assets/earn-more-image.png';

import {
	AppPresentationItemsWrapper,
	ButtonsWrapper,
	RightSectionWrapper,
	SectionItemsWrapper
} from "./styles";

const EcommerceScreen = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.ecommerceScreen;

  return (
	  <StaticPageContainerWithHeaderSpaceFix>
			<HeroSection />
		  <SectionItemsWrapper>
			  <ContentSizeLimiter>
				  <PosPresentationSection />
			  </ContentSizeLimiter>

			  <ContentSizeLimiter>
				  <BenefitsSection />
			  </ContentSizeLimiter>

			  <AppPresentationItemsWrapper>
				  <RightSectionWrapper>
					  <ContentSizeLimiter>
						  <SectionWithSideImage image={earnMoreImage} isReversed>
							  <ContentForSectionWithSideImage
								  {...translationsObject.increaseSales}
							  />
							  <ButtonsWrapper>
								  <DefaultButton
									  color={'#F2B604'}
									  to={CONTACT_US_ROUTE}
								  >
									  {translationsObject.increaseSales.buttonText}
								  </DefaultButton>
							  </ButtonsWrapper>
						  </SectionWithSideImage>
					  </ContentSizeLimiter>
				  </RightSectionWrapper>
			  </AppPresentationItemsWrapper>
		  </SectionItemsWrapper>
	  </StaticPageContainerWithHeaderSpaceFix>
	  );
};

export default EcommerceScreen;
