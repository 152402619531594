import styled from "styled-components";
import {
	DESIGN_ARTIST_ANIMATOR,
	DESIGN_PRODUCT_DESIGN,
	DESIGN_UI_UX_DESIGN,
	DEVELOPMENT_DEVELOPMENT_BACK_END,
	DEVELOPMENT_FRONT_END,
	DEVELOPMENT_MOBILE, MANAGEMENT_CATEGORY, OFFICE_ADMINISTRATION_CATEGORY, SALES_CATEGORY
} from "../../CareerConstants";

export const JobItemContainer = styled.div`
	background-color: #FFFFFF;
	border-radius: 4px;
	padding: 30px;
	
	display: grid;
	grid-template-columns: 2fr 3fr max-content;
	grid-column-gap: 100px;
	align-items: center;
  cursor: pointer;
	color: #0D152E;

  @media all and ${({theme: {device}}) => device.sm} {
    grid-template-columns: 1fr max-content;
  }
`;

export const IconAndTitleContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 15px;
  align-items: center;
`;

export const JobItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #9778E3;

  color: white;
  font-size: 18px;

  &[data-category=${MANAGEMENT_CATEGORY}] {
    background-color: #20acf6;
  }

  &[data-category=${SALES_CATEGORY}] {
    background-color: #ff092e;
  }

  &[data-category=${OFFICE_ADMINISTRATION_CATEGORY}] {
    background-color: #009e97;
  }

  &[data-sub-category=${DESIGN_PRODUCT_DESIGN}] {
    background-color: #F53D55;
  }

  &[data-sub-category=${DESIGN_UI_UX_DESIGN}] {
    background-color: #9778E3;
  }

  &[data-sub-category=${DESIGN_ARTIST_ANIMATOR}] {
    background-color: #F6C25B;
  }

  &[data-sub-category=${DEVELOPMENT_FRONT_END}] {
    background-color: #64DF7B;
  }

  &[data-sub-category=${DEVELOPMENT_MOBILE}] {
    background-color: #F89B58;
  }

  &[data-sub-category=${DEVELOPMENT_DEVELOPMENT_BACK_END}] {
  }
`;

export const JobTitleText = styled.div`
  font-size: 18px;
`;

export const JobDetailsWrapper = styled.div`
  display: grid;
	grid-template-columns: repeat(3, max-content);
	justify-content: space-between;
	color: #81838C;
	font-size: 15px;

  @media all and ${({theme: {device}}) => device.sm} {
    display: none;
  }
`;

