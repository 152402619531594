import styled from 'styled-components';
import {Text} from "../../../../../components/Layout/DynamicContentRenderer/styles";
import listItemImage from './../../assets/list-item-image.png';
import {Cell, HeaderCell} from "../../../../../components/Layout/DynamicContentRenderer/TableRenderer/styles";

export const ContentContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 380px;
	grid-column-gap: 170px;
	
	@media all and ${({theme: {device}}) => device.md} {
    grid-template-columns: 1fr;
		grid-row-gap: 50px;
  }
`;

export const JobDetailsContainer = styled.div`
  display: grid;
  grid-row-gap: 80px;
`;

export const SectionsWrapper = styled.div`
  display: grid;
	grid-row-gap: 60px;
	
	${Text} {
		font-size: 16px;
		line-height: 28px;
		color: #0D152E;
	}
`;

export const SectionItemContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;

  ul {
    padding-inline-start: 0;

    li {
      background: url(${listItemImage}) no-repeat left top 3px;
      background-size: 22px;
      padding: 0 0 0 35px;
      list-style: none;
      margin: 0;
    }
  }

  ${Cell} {
    border-color: #0D152E;
  }

  ${HeaderCell} {
    background-color: rgba(21, 101, 216, 0.62);
	  color: white;
    border-bottom: none;
  }
`;

export const SectionTitle = styled.div`
  color: #001738;
	font-size: 30px;
	line-height: 55px;
`;

export const FormWrapper = styled.div`
  position: relative;
	
	& > * {
		position: sticky;
		top: 100px;
		
		@media all and ${({theme: {device}}) => device.md} {
      position: relative;
      top: initial;
    }
	}
`;
