import React from 'react';
import useItems from "./useItems";
import {ItemContainer, ItemDescription, ItemIcon, ItemsWrapper, ItemTitle} from "./styles";

const BenefitsSection = () => {
	const items = useItems();

  return (
		<ItemsWrapper>
			{items.map(({icon, title, description}, index) => (
				<ItemContainer key={index}>
					<ItemIcon icon={icon} />
					<ItemTitle dangerouslySetInnerHTML={{ __html: title}} />
					<ItemDescription>{description}</ItemDescription>
				</ItemContainer>
			))}
		</ItemsWrapper>
  );
};

export default BenefitsSection;
