import React, {useContext} from 'react';
import {CONTACT_US_ROUTE} from "../../../../library/Navigation/NavigationConstants";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";
import ContentSizeLimiter from "../../../../components/UI/Containers/ContentSizeLimiter";
import DefaultButton from "../../../../components/UI/Buttons/DefaultButton";

import {
	ContentWrapper,
	SectionContainer, SectionContentItemsWrapper,
	ImageContainer,
	SectionDescription,
	SectionTitle, FooterContainer, StatItemContainer, StatItemText, StatItemValue
} from "./styles";

const HeroSection = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.aboutUs.hero;

  return (
    <SectionContainer>
			<ContentSizeLimiter>
				<ContentWrapper>
					<ImageContainer />
					<SectionContentItemsWrapper>
						<SectionTitle>{translationsObject.title}</SectionTitle>
						<SectionDescription>{translationsObject.description}</SectionDescription>
						<FooterContainer>

							<DefaultButton to={CONTACT_US_ROUTE} verticalPadding={15} horizontalPadding={40} color={'#1565D8'} textColor={'white'}>
								{translationsObject.buttonText}
							</DefaultButton>
							<StatItemContainer>
								<StatItemValue>4<span>+</span></StatItemValue>
								<StatItemText>{translationsObject.countriesText}</StatItemText>
							</StatItemContainer>
							<StatItemContainer>
								<StatItemValue>7978<span>+</span></StatItemValue>
								<StatItemText>{translationsObject.membersText}</StatItemText>
							</StatItemContainer>

						</FooterContainer>
					</SectionContentItemsWrapper>
				</ContentWrapper>
			</ContentSizeLimiter>
    </SectionContainer>
  );
};

export default HeroSection;
