import React, {useContext, useMemo} from 'react';

import CompanyLogo from "../../../../../../components/Atomic/Branding/CompanyLogo";
import ContentSizeLimiter from "../../../../../../components/UI/Containers/ContentSizeLimiter";
import FooterCopyrightBar from "../components/FooterCopyrightBar";

import {
	CategoryContainer, CategoryItemContainer, CategoryItemsWrapper, CategoryTitle,
	ComponentContainer,
	ContentContainer,
	CopyrightBarWrapper, DisclaimerText, ItemLink, LinkSectionsWrapper,
	LogoAndLinksWrapper, NewLinkBadge
} from "./styles";

import useLinks from "./useLinks";
import {LocalisationContext} from "../../../../../Providers/SharedProviders/LocalisationProvider";
import Environment from "../../../../../Environment";
import {APP_REGION_AE, APP_REGION_CY} from "../../../../../Environment/EnvironmentConstants";

const DefaultFooter = () => {
	const {translations} = useContext(LocalisationContext);
	const linkSections = useLinks();

	return (
    <ComponentContainer>
	    <ContentSizeLimiter>
				<ContentContainer>
					<DisclaimerText
						dangerouslySetInnerHTML={{__html: Environment.APP_REGION === APP_REGION_CY || Environment.APP_REGION === APP_REGION_AE ? translations.sections.staticPageFooter.disclaimerTextCY : translations.sections.staticPageFooter.disclaimerText}}
					/>
					<LogoAndLinksWrapper>
						<div><CompanyLogo colorVariant={'white'} /></div>
						<LinkSectionsWrapper categories={linkSections.length}>
							{linkSections.map(({category, items}, categoryIndex) => (
								<CategoryContainer key={`c-${categoryIndex}`}>
									<CategoryTitle>{category}</CategoryTitle>
									<CategoryItemsWrapper numberOfItems={items.length}>
										{items.map(({title, to, target, isNew}, index) =>
											<CategoryItemContainer key={`${categoryIndex}-l-${index}`}>
												<ItemLink to={to} target={target}>{title}</ItemLink>
												{isNew === true && (
													<NewLinkBadge>NEW</NewLinkBadge>
												)}
											</CategoryItemContainer>
										)}
									</CategoryItemsWrapper>
								</CategoryContainer>
							))}
						</LinkSectionsWrapper>
					</LogoAndLinksWrapper>
				</ContentContainer>
	    </ContentSizeLimiter>

	    <CopyrightBarWrapper>
		    <ContentSizeLimiter>
				<FooterCopyrightBar hasLanguageSelector={false}
									facebookUrl={Environment.socialLinks.facebookUrl}
									twitterUrl={Environment.socialLinks.twitterUrl}
									instagramUrl={Environment.socialLinks.instagramUrl}
									linkedInUrl={Environment.socialLinks.linkedInUrl}
				/>
		    </ContentSizeLimiter>
	    </CopyrightBarWrapper>
    </ComponentContainer>
  );
};

export default DefaultFooter;
