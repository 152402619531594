import React from 'react';
import ReactDOM from 'react-dom';
import './library/Theme/DefaultTheme/fonts/style.css';
import DefaultTheme from "./library/Theme/DefaultTheme";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import GlobalProviders from "./library/Providers/SharedProviders/GlobalProviders";
import ApplicationRouter from "./library/Navigation/ApplicationRouter";
import {BrowserRouter} from "react-router-dom";
import NotificationProvider from "./library/Notification/NotificationProvider";
import CookieBanner from "./components/Sections/CookieBanner";

const theme = createTheme({
	palette: {
		primary: {
			main: DefaultTheme.colors.blue[600],
		}
	},
	typography: {
		fontFamily: [
			'Basier Circle',
		].join(','),
	}
});

const App = () => {
	return(
		<React.Fragment>
			<GlobalProviders>
				<NotificationProvider />
				<ThemeProvider theme={theme}>
					<BrowserRouter>
						<ApplicationRouter />
						<CookieBanner />
					</BrowserRouter>
				</ThemeProvider>
			</GlobalProviders>
		</React.Fragment>
	);
};

ReactDOM.render(
	<App />,
	document.getElementById('root')
);
