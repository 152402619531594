import styled from "styled-components";

export const ItemsWrapper = styled.div`
  display: grid;
	grid-row-gap: 35px;
`;


export const TitleText = styled.div`
  font-size: 60px;
`;

export const SubTitleText = styled.div`
	font-size: 36px;
	color: #1565D8;
`;

export const DescriptionsWrapper = styled.div`
  display: grid;
	grid-row-gap: 20px;
`;

export const DescriptionText = styled.div`
	font-size: 17px;
	line-height: 37px;
`;

