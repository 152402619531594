import React, {useMemo} from 'react';

import StaticPageContainerWithHeaderSpaceFix
	from "../../components/Layout/StaticPageContainerWithHeaderSpaceFix";
import ContactFormSection from "../../components/Sections/ContactFormSection";
import PartnersSection from "./components/PartnersSection";
import ContentSizeLimiter from "../../components/UI/Containers/ContentSizeLimiter";

import WhatWeOffer from "./components/WhatWeOffer";
import HeroSection from "./components/HeroSection";

import {
	ScreenContainer,
	SectionsWrapper,
	ContactFormContainer,
	HeroSectionContainer, BackgroundImageContainer, SectionsContainer, HeroSectionWrapper
} from "./styles";

import useHeroSectionSkewValues from "./useHeroSectionSkewValues";
import useWindowSize from "../../library/Helpers/useWindowSize";

const PartnersScreen = () => {
	const heroSectionSkewValues = useHeroSectionSkewValues();
	const windowSize = useWindowSize();
	const [imagePositionOffset, imagePositionOffsetMd] = useMemo(() => {
		return [(windowSize.width - 1920), windowSize.width - 1280];
	}, [windowSize]);

	return (
	  <StaticPageContainerWithHeaderSpaceFix>
		  <ScreenContainer>
			  <BackgroundImageContainer imagePositionOffset={imagePositionOffset} imagePositionOffsetMd={imagePositionOffsetMd}>
				  <HeroSectionContainer
					  left={heroSectionSkewValues.left}
					  right={heroSectionSkewValues.right}
				  >
					  <HeroSectionWrapper>
						  <HeroSection />
					  </HeroSectionWrapper>
				  </HeroSectionContainer>
				  <SectionsContainer>
					  <ContentSizeLimiter>
						  <SectionsWrapper>
							  <WhatWeOffer />
							  <PartnersSection />
						  </SectionsWrapper>
					  </ContentSizeLimiter>
				  </SectionsContainer>
			  </BackgroundImageContainer>
			  <ContactFormContainer left={25} right={85}>
				  <ContactFormSection />
			  </ContactFormContainer>
		  </ScreenContainer>
	  </StaticPageContainerWithHeaderSpaceFix>
  );
};

export default PartnersScreen;
