import {useMemo} from "react";
import {
	ABOUT_US_ROUTE,
	CAREER_ROUTE,
	CONTACT_US_ROUTE,
	ECOMMERCE_ROUTE,
	FAQ_ROUTE,
	POS_ROUTE,
	REGISTER_CORPORATE_ROUTE,
	REGISTER_PRIVATE_ROUTE,
	SECURITY_ROUTE,
	TECHNOLOGY_ROUTE
} from "../../../../../Navigation/NavigationConstants";
import Environment from "../../../../../Environment";

export default function useMenuItems(){
	return useMemo(() => [
		{
			text:'Company',
			hasSubMenu: true,
			items: [
				{
					text: 'About Us',
					to: ABOUT_US_ROUTE,
				},
				{
					text: 'Careers',
					to: CAREER_ROUTE,
				},
				{
					text: 'Contact Us',
					to: CONTACT_US_ROUTE
				},
				{
					text: 'FAQ',
					to: FAQ_ROUTE
				}
			]
		},
		{
			text:'Solutions',
			hasSubMenu: true,
			items: [
				// {
				// 	text: 'POS',
				// 	to: POS_ROUTE,
				// },
				{
					text: 'E-Commerce',
					to: ECOMMERCE_ROUTE
				}
			]
		},
		{text:'Technology', to: TECHNOLOGY_ROUTE},
		{text:'Security', to: SECURITY_ROUTE},
		{text:'Blog', to: {pathname: Environment.blogURL}, target: '_parent'},
		{
			text:'Sign up',
			hasSubMenu: true,
			items: [
				{
					text: 'Individual',
					to: {pathname: REGISTER_PRIVATE_ROUTE}, target: '_parent'
				},
				{
					text: 'Corporate',
					to: {pathname: REGISTER_CORPORATE_ROUTE}, target: '_parent'
				}
			]
		}
	], [])
}
