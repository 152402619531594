import styled from 'styled-components';

import ContentSizeLimiter from "../../../../../../components/UI/Containers/ContentSizeLimiter";
import {Link} from "react-router-dom";
import {MenuItemContainer, MenuItemText} from "./components/MenuSection/components/MenuItem/styles";

export const HeaderContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-items: center;

  position: fixed;
  z-index: 2;
  height: 80px;

  background-color: #fff;
  box-shadow: 0 2px 10px 2px rgba(150, 150, 150, 0.12);
  color: #748292;
  width: 100%;

  &[data-header-type='dark-transparent'] {
    background-color: transparent;
    box-shadow: 0 2px 10px 2px rgba(31, 31, 31, 0.12);
	  
	  &[data-scrolled=true]{
		  background-color: #1A1A1A;
	  }
	  
	  ${MenuItemContainer} > ${MenuItemText} {
      color: white;
    }
  }
`;

export const StyledContentSizeLimiter = styled(ContentSizeLimiter)`
  width: 100%;
`;

export const MenuSectionsWrapper = styled.div`
  display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
`;

export const LogoWrapper = styled(Link)`
	img {
		max-width: 120px;
	}
`;

