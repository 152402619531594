import React from 'react';
import {ItemsWrapper, DescriptionsWrapper, DescriptionText, TitleText} from "./styles";

const ContentForSectionWithSideImage = ({title, description, descriptions, subTitle = null}) => {
	descriptions = descriptions || [description];

  return (
    <ItemsWrapper>
	    <TitleText dangerouslySetInnerHTML={{ __html: title}} />
	    <DescriptionsWrapper>
		    {descriptions.map((description, item) => (
			    <DescriptionText>{description}</DescriptionText>
		    ))}
	    </DescriptionsWrapper>
    </ItemsWrapper>
  );
};

export default ContentForSectionWithSideImage;
