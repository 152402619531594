import React, {useMemo} from 'react';
import {useParams} from "react-router-dom";

import StaticPageContainerWithHeaderSpaceFix from "../../../components/Layout/StaticPageContainerWithHeaderSpaceFix";
import ContentSizeLimiter from "../../../components/UI/Containers/ContentSizeLimiter";
import {jobsMappedById} from "../useJobs";

import JobView from "./components/JobView";
import {PageContainer} from "./styles";

const CareerDetailsScreen = () => {
  let { jobId } = useParams();
  const job = useMemo(
    () => jobsMappedById.hasOwnProperty(jobId) ? jobsMappedById[jobId] : null,
    [jobId]
  );

  return (
    <StaticPageContainerWithHeaderSpaceFix>
      <ContentSizeLimiter>
        <PageContainer>
          <React.Suspense fallback={React.Fragment}>
            {job !== null && (
              <JobView job={job} />
            )}
          </React.Suspense>
        </PageContainer>
      </ContentSizeLimiter>
    </StaticPageContainerWithHeaderSpaceFix>
  );
};

export default CareerDetailsScreen;
