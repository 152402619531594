import styled from 'styled-components';
import leftImage from './assets/hero-section-left-image.png';
import playButtonImage from './assets/play-button-image.png';
import backgroundImage from './assets/hero-background-image.png';

export const SectionContainer = styled.div`
	position: relative;
	background-color: #101C3D;
	background-image: url(${backgroundImage});
	padding: 100px 0;
	
	&:before {
    position: absolute;
		top: -1px;
		left: 0;
		height: calc(100% + 1px);
		content: "";
		width: 40%;
		background-image: url(${leftImage});
		background-size: cover;

    @media all and ${({theme: {device}}) => device.md} {
      display: none;
    }
	}
`;

export const ContentWrapper = styled.div`
  display: grid;
	justify-items: end;
`;

export const ContentContainer = styled.div`
  display: grid;
	grid-row-gap: 40px;
	width: 50%;
	
	@media all and ${({theme: {device}}) => device.md} {
		width: initial;
		padding: 50px;
	}

  @media all and ${({theme: {device}}) => device.sm} {
    padding: 30px;
  }
`;

export const SectionTitle = styled.div`
  color: white;
	font-size: 54px;
  line-height: 1.1;
	max-width: 450px;
	
  @media all and ${({theme: {device}}) => device.sm} {
    font-size: 49px;
  }
`;

export const SectionDescriptionsWrapper = styled.div`
  display: grid;
  grid-row-gap: 40px;
`;

export const SectionDescription = styled.div`
  color: #7987AC;
	font-size: 18px;
  line-height: 1.5;
`;
