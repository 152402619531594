import {useNotEmptyValidationRule} from "../../../../../library/Validation/validators/notEmptyValidator";
import {useEmailValidator} from "../../../../../library/Validation/validators/emailValidator";
import {validateSingle} from "../../../../../library/Validation";

export function useJobApplicationFormValidation({name, email, cvUrl, details}) {
	const notEmptyConstraint = useNotEmptyValidationRule();
	const emailConstraint = useEmailValidator();

	const nameError = validateSingle(name, notEmptyConstraint);
	const emailError = validateSingle(email, {
		...notEmptyConstraint,
		...emailConstraint
	});
	const cvUrlError = validateSingle(cvUrl, notEmptyConstraint);
	const detailsError = validateSingle(details, notEmptyConstraint);

	return {
		nameError,
		emailError,
		cvUrlError,
		detailsError,
		hasError: ((nameError || emailError || cvUrlError || detailsError) || '').length > 0,
	};
}
