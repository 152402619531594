import React from 'react';
import Breadcrumbs from "../../../../../components/Layout/Breadcrumbs";

import {ComponentContainer} from "./components/Styled";

const ScreenHeaderWithBreadcrumbs = ({children, breadcrumbs}) => {
  return (
    <ComponentContainer>
	    <Breadcrumbs items={breadcrumbs} />
	    <div>
		    {children}
	    </div>
    </ComponentContainer>
  );
};

export default ScreenHeaderWithBreadcrumbs;
