import {useContext, useMemo} from "react";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import customersIcon from './../../assets/whole-world-icon.png';
import securityIcon from './../../assets/superior-security-icon.png';
import salesIcon from './../../assets/increase-sales-icon.png';

export default function useItems(){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.ecommerceScreen.benefitsSection;

	return useMemo(() => [
		{
			icon: customersIcon,
			...translationsObject.customers
		},
		{
			icon: securityIcon,
			...translationsObject.security
		},
		{
			icon: salesIcon,
			...translationsObject.sales
		},
	], [translationsObject]);
}
