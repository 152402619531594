import styled from 'styled-components';
import {SwipeableDrawer} from "@material-ui/core";
import {Close} from "@material-ui/icons";

import {MenuItemContainer, SubMenuWrapper} from "../MenuSection/components/MenuItem/styles";
import {MenuItemsWrapper, MenuSectionContainer} from "../MenuSection/styles";

export const MenuDrawerComponent = styled(SwipeableDrawer)``;

export const MenuCloseButton = styled(Close)`
  color: black;
	font-size: 35px;
	align-self: flex-end;
	padding: 10px;
`;

export const MenuSectionContentContainer = styled.div`
	overflow-y: auto;
	box-sizing: border-box;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 30px;

	@media all and ${({theme: {device}}) => device.sm} {
		width: 50vw;
	}

	@media all and ${({theme: {device}}) => device.xs} {
		width: 80vw;
	}
	
	${MenuSectionContainer} {
		grid-template-columns: 1fr;
		grid-row-gap: 80px;
		justify-items: center;
	
		${MenuItemsWrapper} {
			grid-template-columns: 1fr;
			grid-row-gap: 30px;
			
			${MenuItemContainer} {
				${SubMenuWrapper} {
					position: relative;
					display: block;
					padding-top: 0;
					padding-bottom: 20px;
				}
			}
		}
	}
`;


