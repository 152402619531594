import {useEffect, useMemo} from 'react'
import {useLocation} from 'react-router-dom'
import ReactGA from 'react-ga';

import Environment from "../../Environment";

export const useGoogleAnalytics = () => {
	const {pathname, search} = useLocation();

	const analyticsId = Environment.GOOGLE_ANALYTICS_ID;
	const isGoogleAnalyticsEnabled = Environment.GOOGLE_ANALYTICS_ID !== null;

	const path = useMemo(() => '' + pathname + search, [pathname, search]);
	const trackPageVisit = () => isGoogleAnalyticsEnabled === true && ReactGA.pageview(path);

	useEffect(() => {
		if (isGoogleAnalyticsEnabled === true) {
			ReactGA.initialize(analyticsId);
			trackPageVisit();
		}
	}, [isGoogleAnalyticsEnabled, analyticsId]);

	useEffect(trackPageVisit, [path]);
}
