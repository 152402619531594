import styled from "styled-components";
import backgroundImage from './../../assets/hero-background.png';
import heroImage from './../../assets/hero-image.png';

export const SectionContainer = styled.div`
	position: relative;
	padding: 120px 0;
	background: #101C3D url(${backgroundImage});
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	color: white;
	
	@media all and ${({theme: {device}}) => device.xs} {
		padding: 80px 0;
	}

  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 48%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(${heroImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media all and ${({theme: {device}}) => device.lg} {
      background-position: center;
    }

    @media all and ${({theme: {device}}) => device.md} {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 100px;
	
	@media all and ${({theme: {device}}) => device.md} {
		grid-template-columns: 1fr;
		justify-items: center;
	}
`;

export const ImageContainer = styled.div`
	@media all and ${({theme: {device}}) => device.md} {
		display: none;
	}
`;

export const SectionContentItemsWrapper = styled.div`
  display: grid;
	grid-row-gap: 35px;

	@media all and ${({theme: {device}}) => device.md} {
		max-width: 80%;
	}

	@media all and ${({theme: {device}}) => device.sm} {
		max-width: initial;
		padding: 30px 0;
	}
`;

export const SectionTitle = styled.div`
	font-size:57px;
	line-height: 71px;
	max-width: 500px;
`;

export const SectionDescription = styled.div`
	font-size: 20px;
	line-height: 34px;
  color: #687497;
`;

export const FooterContainer = styled.div`
  display: flex;
	align-items: center;
	column-gap: 30px;
	
	@media all and ${({theme: {device}}) => device.xs} {
		display: grid;
		grid-row-gap: 40px;
		justify-items: center;
	}
`;

export const StatItemContainer = styled.div`
  display: grid;

	@media all and ${({theme: {device}}) => device.xs} {
		justify-items: center;
		grid-row-gap: 5px;
	}
`;

export const StatItemValue = styled.div`
	font-size: 32px;
  span {
	  color: #F2B604
  }
`;

export const StatItemText = styled.div`
  font-size: 18px;
	color: #687497;
`;


