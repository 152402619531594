export const DESIGN_CATEGORY = 'design';
export const DEVELOPMENT_CATEGORY = 'development';
export const MANAGEMENT_CATEGORY = 'management';
export const SALES_CATEGORY = 'sales';
export const OFFICE_ADMINISTRATION_CATEGORY = 'office-administration';
export const ALL_CATEGORIES = [DESIGN_CATEGORY, DEVELOPMENT_CATEGORY, MANAGEMENT_CATEGORY];

export const DESIGN_PRODUCT_DESIGN = 'product-design';
export const DESIGN_UI_UX_DESIGN = 'ui-ux-design';
export const DESIGN_ARTIST_ANIMATOR = 'artist-animator';

export const DEVELOPMENT_FRONT_END = 'development-front-end';
export const DEVELOPMENT_MOBILE = 'development-mobile';
export const DEVELOPMENT_DEVELOPMENT_BACK_END = 'development-back-end';

export const SUBCATEGORIES_BY_CATEGORY = {
	[DESIGN_CATEGORY]: [
		DESIGN_PRODUCT_DESIGN,
		DESIGN_UI_UX_DESIGN,
		DESIGN_ARTIST_ANIMATOR,
	],
	[DEVELOPMENT_CATEGORY]: [
		DEVELOPMENT_FRONT_END,
		DEVELOPMENT_MOBILE,
		DEVELOPMENT_DEVELOPMENT_BACK_END,
	]
};
