import React, {useContext, useMemo} from "react";
import {LocalisationContext} from "../../library/Providers/SharedProviders/LocalisationProvider";

import m30Image from './assets/M30_pos_image.png';
import e500Image from './assets/E500_pos_image.png';
import e600Image from './assets/E600_pos_image.png';
import e700Image from './assets/E700_pos_image.png';
import e800Image from './assets/E800_pos_image.png';
import a920ProImage from './assets/A920_PRO_pos_image.png';
import a60ProImage from './assets/A60_pos_image.png';
import a80ProImage from './assets/A80_pos_image.png';

export function usePosCategories(){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.common.posCategories;

	return useMemo(() => [
		{key: "all", title: translationsObject.all.name},
		{key: "portable", title: translationsObject.portable.name},
		{key: "mobile", title: translationsObject.mobile.name},
		{key: "countertop", title: translationsObject.countertop.name},
		{key: "unattended", title: translationsObject.unattended.name},
	], [translationsObject])
}

export function usePosItemsForCategory(selectedCategoryKey){
	return useMemo(() => {
		if(CATEGORY_POS_MAPPING.hasOwnProperty(selectedCategoryKey)){
			return CATEGORY_POS_MAPPING[selectedCategoryKey];
		}

		return [];
	}, [selectedCategoryKey]);
}

export function usePosItems(){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.common.posItems;

	return useMemo(() => {
		return {
			'm30': {
				...translationsObject.m30,
				image: m30Image
			},
			'e500': {
				...translationsObject.e500,
				image: e500Image
			},
			'e600': {
				...translationsObject.e600,
				image: e600Image
			},
			'e700': {
				...translationsObject.e700,
				image: e700Image
			},
			'e800': {
				...translationsObject.e800,
				image: e800Image
			},
			'a920_pro': {
				...translationsObject.a920_pro,
				image: a920ProImage
			},
			'a80': {
				...translationsObject.a80,
				image: a80ProImage
			},
			'a60': {
				...translationsObject.a60,
				image: a60ProImage
			},
		}
	}, [translationsObject]);
}

export function useSelectedPosItem(selectedPosId){
	const items = usePosItems();

	return useMemo(() => {
		if(items.hasOwnProperty(selectedPosId)){
			return items[selectedPosId];
		}

		return React.Fragment;
	}, [selectedPosId, items]);
}


const CATEGORY_POS_MAPPING = {
	'all': ['m30', 'e500', 'e600', 'e700', 'e800', 'a920_pro', 'a60', 'a80'],
	'portable': ['a920_pro', 'a80', 'a60'],
	'mobile': ['m30'],
	'countertop': ['e600', 'e700'],
	'unattended': ['e500', 'e800'],
}
