import styled from "styled-components";
import ContainerWithSkewBackground
	from "../../components/UI/Containers/ContainerWithSkewBackground";

import backgroundImage from "./assets/partners-screen-hero-background.png";

export const ScreenContainer = styled.div`
	position: relative;
`;

export const HeroSectionContainer = styled(ContainerWithSkewBackground)`
	padding-top: 0;

	&::after {
		background-color: #181A29;
		@media all and ${({theme: {device}}) => device.sm} {
			display: none;
		}
	}
`;

export const HeroSectionWrapper = styled.div`
  padding: 120px 0 300px;
	
	@media all and ${({theme: {device}}) => device.sm} {
		padding-bottom: 120px;
	}
`;

const topImageStartingPosition = 50;
const topImageStartingPositionMd = 210;
export const BackgroundImageContainer = styled.div`
  position: relative;
	
	&::after {
    content: "";
    position: absolute;
		top: 0;
		right: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
		
    background-image: url(${backgroundImage});
    background-size: ${props => 1000 - (props.imagePositionOffset * -1) / 3}px;
    background-repeat: no-repeat;
    background-position: 
	    top ${props => topImageStartingPosition - props.imagePositionOffset / 6}px 
	    right -${props => 240 - (props.imagePositionOffset * -1) / 10}px;
		
		@media all and ${({theme: {device}}) => device.md} {
      background-position:
        top ${props => topImageStartingPositionMd - props.imagePositionOffsetMd / 6}px
        right -${props => 240 - (props.imagePositionOffset * -1) / 10}px;
    }
		
		@media all and ${({theme: {device}}) => device.sm} {
			display: none;
		}
	}
`;

export const SectionsContainer = styled.div`
  background-color: #181A29;
	* {
    z-index: 1;
	}
`;

export const SectionsWrapper = styled.div`
	display: grid;
	grid-row-gap: 80px;
	padding: 150px 0;
`;

export const ContactFormContainer = styled(ContainerWithSkewBackground)`
	padding: 70px;
	
	&::after {
		background-color: #00113B;
	}
`;
