import React, {useMemo} from 'react';

import {
  ComponentContainer,
  DetailsTabContentContainer,
  DetailsTabCookieTypeTitle,
  DetailsTabCookieTypeText,
  DetailsTabContentTextsWrapper
} from "./styles";
import {Switch} from "@material-ui/core";
import {COOKIE_POLICY_ROUTE, PRIVACY_POLICY_ROUTE} from "../../../../../library/Navigation/NavigationConstants";

const DetailsTabContent = ({options}) => {

  const optionsWithLinksReplaced = useMemo(() => options.map((item) => {
    return {
      ...item,
      text: item.text.replaceParameters({
        cookiePolicyLink: COOKIE_POLICY_ROUTE,
      })
    }
  }), [options])

  return (
    <ComponentContainer>
	    {optionsWithLinksReplaced.map((item, index) => (
				<DetailsTabContentContainer key={index}>
          <DetailsTabContentTextsWrapper>
            <DetailsTabCookieTypeTitle>{item.title}</DetailsTabCookieTypeTitle>
            <DetailsTabCookieTypeText dangerouslySetInnerHTML={{__html: item.text}} />
          </DetailsTabContentTextsWrapper>
          <Switch disabled={item.disabled} checked={item.checked} color={'primary'} onChange={item.onChange} />
				</DetailsTabContentContainer>
	    ))}
    </ComponentContainer>
  );
};

export default DetailsTabContent;
