import React, {useContext} from 'react';
import {Facebook, Instagram, LinkedIn, Twitter} from "@material-ui/icons";
import {LocalisationContext} from "../../../../../../Providers/SharedProviders/LocalisationProvider";

import {
	CopyrightText,
	FooterCopyrightBarContainer,
	SocialIconItem,
	SocialIconsContainer,
} from "./styles";

export default function FooterCopyrightBar({facebookUrl, twitterUrl, instagramUrl, linkedInUrl}){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.sections.staticPageFooter;

  return (
    <FooterCopyrightBarContainer>
	    <CopyrightText>
		    {translationsObject.copyrightText}
	    </CopyrightText>
      <SocialIconsContainer>
	      <SocialIconItem target={'_blank'} href={facebookUrl}>
		      <Facebook />
	      </SocialIconItem>
	      <SocialIconItem target={'_blank'} href={twitterUrl}>
		      <Twitter />
	      </SocialIconItem>
	      <SocialIconItem target={'_blank'} href={instagramUrl}>
		      <Instagram />
	      </SocialIconItem>
	      <SocialIconItem target={'_blank'} href={linkedInUrl}>
		      <LinkedIn />
	      </SocialIconItem>
      </SocialIconsContainer>
    </FooterCopyrightBarContainer>
  );
};
