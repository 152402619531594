import styled from "styled-components";
import backgroundImageFrom from './../../assets/papel-faces-background-image.png';

export const SectionContainer = styled.div`
	display: grid;
	grid-row-gap: 50px;

	@media all and ${({theme: {device}}) => device.sm} {
		grid-row-gap: 80px;
	}
`;

export const SectionContentContainer = styled.div`
  display: grid;
	grid-row-gap: 40px;
	text-align: center;
	width: 66%;
	justify-self: center;
	
	@media all and ${({theme: {device}}) => device.sm} {
		width: initial;
	}
`;

export const SectionTitle = styled.div`
  font-size: 48px;
	line-height: 60px;
`;

export const SectionDescription = styled.div`
	font-size: 17px;
	line-height: 28px;
	color: #687497;
`;

export const ButtonContainer = styled.div`
	display: grid;
	align-items: end;
	justify-content: center;
	background-image: url(${backgroundImageFrom});
	background-size:  auto 100%;
	background-position: bottom -25px center;
	background-repeat: no-repeat;
	min-height: 300px;
	padding: 80px 0;
	
	@media all and ${({theme: {device}}) => device.sm} {
		background-size:  100% auto;
		align-items: start;
		padding: 20px 0 80px 0;
	}

	@media all and ${({theme: {device}}) => device.xs} {
		min-height: 200px;
	}
`;

