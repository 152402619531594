import Environment from "../Environment";

export const HOMEPAGE_ROUTE = '/';
export const SIGNUP_ROUTE = '/signup';
export const NOT_FOUND_ROUTE = '/404';

/**
 * Landing pages
 */
export const CONTACT_US_ROUTE = '/contact';
export const FAQ_ROUTE = '/faq';
export const LOGO_GUIDELINES_ROUTE = '/logo-guidelines';
export const ABOUT_US_ROUTE = '/about';
export const SECURITY_ROUTE = '/security';

export const PARTNERS_ROUTE = '/partners';
export const ECOMMERCE_ROUTE = '/e-commerce';
export const TECHNOLOGY_ROUTE = '/technology';
export const POS_ROUTE = '/pos';

export const REGISTER_PRIVATE_ROUTE = Environment.LoginURL + '/#/registration/private'
export const REGISTER_CORPORATE_ROUTE = Environment.LoginURL + '/#/registration/corporate'

export const  CAREER_ROUTE = '/careers';
export const CAREER_DETAIL_ROUTE_BASE = '/careers/job/';
export const CAREER_DETAIL_ROUTE = CAREER_DETAIL_ROUTE_BASE + ':jobId';
export function getCareerJobLink(jobId){
	return CAREER_DETAIL_ROUTE_BASE + jobId;
}

export const LANDING_PAGE_ROUTES = [
	CONTACT_US_ROUTE,
	FAQ_ROUTE,
	LOGO_GUIDELINES_ROUTE,
	ABOUT_US_ROUTE,
	SECURITY_ROUTE,
	ECOMMERCE_ROUTE,
	PARTNERS_ROUTE,
	 CAREER_ROUTE,
	CAREER_DETAIL_ROUTE,
	TECHNOLOGY_ROUTE,
	POS_ROUTE
];

/**
 * Legal Routes
 */
export const TOS_ROUTE = '/terms-and-conditions';
export const COOKIE_POLICY_ROUTE = '/cookie-policy';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const PRIVACY_POLICY_JOB_APPLICANTS_ROUTE = '/job-applicants-policy';

export const LEGAL_SCREEN_ROUTES = [
	TOS_ROUTE,
	COOKIE_POLICY_ROUTE,
	PRIVACY_POLICY_ROUTE,
	PRIVACY_POLICY_JOB_APPLICANTS_ROUTE
];
