import React, {useContext} from 'react';

import {CONTACT_US_ROUTE} from "../../../../library/Navigation/NavigationConstants";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";
import DefaultButton from "../../../../components/UI/Buttons/DefaultButton";

import {
	SectionContainer,
	SectionContentContainer,
	SectionTitle,
	SectionDescription,
	ButtonContainer
} from "./styles";

const PapelFacesSection = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.aboutUs.papelFaces;

  return (
    <SectionContainer>
	    <SectionContentContainer>
		    <SectionTitle>{translationsObject.title}</SectionTitle>
		    <SectionDescription>{translationsObject.description}</SectionDescription>
	    </SectionContentContainer>
	    <ButtonContainer>
		    <DefaultButton
			    color={'#0069F0'}
			    textColor={'white'}
			    to={CONTACT_US_ROUTE}
		    >Contact Us</DefaultButton>
	    </ButtonContainer>
    </SectionContainer>
  );
};

export default PapelFacesSection;
