import React from 'react';

import eCompressingLogo from './../../assets/e-compressing-logo.png';
import paxLogo from './../../assets/pax-logo.png';
import visaLogo from './../../assets/visa-logo.png';
import mastercardLogo from './../../assets/mastercard-logo.png';

import {SectionContainer, TitleText, LogosWrapper, LogoItem} from "./styles";

const PartnersSection = () => {
	const logos = [eCompressingLogo,
		paxLogo,
		visaLogo,
		mastercardLogo];
  return (
    <SectionContainer>
	    <TitleText>Our Partners</TitleText>
	    <LogosWrapper>
		    {logos.map((logo, index) => (
		    	<LogoItem title={''} key={index} src={logo} />
		    ))}
	    </LogosWrapper>
    </SectionContainer>
  );
};

export default PartnersSection;
