export default class ApiService {
	static getRequestOptions(model){
		const data = JSON.stringify(model);
		return {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: data,
			redirect: 'follow'
		};
	}

	static sendContactMessage(model){
		return fetch(`/api/send-contact-email`, this.getRequestOptions(model))
	}

	static sendJobApplicationMessage(model){
		return fetch(`/api/send-job-application-email`, this.getRequestOptions(model))
	}
}
