import React, {useContext} from 'react';
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import useBenefitItems from "./useBenefitItems";
import {
	ItemContainer, SectionItemsWrapper, ItemTitle,
	ItemDescription,
	ItemLogo, ItemFirstRowContainer, SectionTitle
} from "./styles";
import CompanyLogo from "../../../../components/Atomic/Branding/CompanyLogo";

const BenefitsSection = () => {
	const {translations} = useContext(LocalisationContext);
	const items = useBenefitItems();

  return (
    <div>
	    <SectionItemsWrapper>
		    <SectionTitle>
			    {translations.staticScreens.aboutUs.benefitsSection.title}
			    <CompanyLogo colorVariant={'primary'} />
		    </SectionTitle>
		    {items.map((item, index) => (
			    <ItemContainer>
				    <ItemFirstRowContainer>
					    <ItemLogo icon={item.icon} />
					    <ItemTitle>{item.title}</ItemTitle>
				    </ItemFirstRowContainer>
				    <ItemDescription>{item.description}</ItemDescription>
			    </ItemContainer>
		    ))}
	    </SectionItemsWrapper>
    </div>
  );
};

export default BenefitsSection;
