import styled from "styled-components";

export const BreadcrumbsWrapper = styled.div`
	display: flex;
	column-gap: 10px;
	align-items: center;
	justify-items: center;
	
	font-size: 15px;
  color: #ADBDCC;
	font-weight: 400;
`;

export const BreadcrumbsText = styled.div``;

