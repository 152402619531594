import {useNotEmptyValidationRule} from "../../../library/Validation/validators/notEmptyValidator";
import {useEmailValidator} from "../../../library/Validation/validators/emailValidator";
import {validateSingle} from "../../../library/Validation";

export function useContactFormDataValidation({fullName, email, subject, message}) {
	const notEmptyConstraint = useNotEmptyValidationRule();
	const emailConstraint = useEmailValidator();

	const fullNameError = validateSingle(fullName, notEmptyConstraint);
	const emailError = validateSingle(email, {
		...notEmptyConstraint,
		...emailConstraint
	});
	const subjectError = validateSingle(subject, notEmptyConstraint);
	const messageError = validateSingle(message, notEmptyConstraint);

	return {
		fullNameError,
		emailError,
		subjectError,
		messageError,
		hasError: ((fullNameError || emailError || subjectError || messageError) || '').length > 0,
	};
}
