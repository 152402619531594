import styled from 'styled-components'
import backgroundImage from './assets/background-image.png';

export const PageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 70px;

  width: 100vw;
  height: 100vh;

  padding: 50px;
`;

export const LogoContainer = styled.div`
  display: grid;
  justify-items: center;

  img {
    max-width: 200px;
    justify-self: center;
  }
`;

export const ImageContainer = styled.div`
  background-image: url(${backgroundImage});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-row-gap: 10px;
  text-align: center;
`;

export const ComingSoonLargeText = styled.div`
  font-size: 32px;
  line-height: 1.4;
`;

export const ComingSoonSmallText = styled.div`
  font-size: 16px;
  color: #666;
`;
