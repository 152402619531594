import React, {useContext, useState} from 'react';

import {LocalisationContext} from "../../../../../library/Providers/SharedProviders/LocalisationProvider";
import ApiService from "../../../../../module/Api/ApiService";

import DefaultButton from "../../../../../components/UI/Buttons/DefaultButton";

import FormTextField from "./components/FormTextField";
import {useJobApplicationFormValidation} from "./validation";
import {FormContentContainer, FormTitleText, JobApplicationFormContainer} from "./styles";
import {createErrorNotification, createInformationalNotification} from "../../../../../library/Notification";

const JobApplicationForm = ({jobId, jobName}) => {
  const {translations} = useContext(LocalisationContext);
  const translationsObject = translations.staticScreens.careerJobDetailScreen.form;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cvUrl, setCvUrl] = useState('');
  const [details, setDetails] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const formValidation = useJobApplicationFormValidation({
    name,
    email,
    cvUrl,
    details
  });

  const handleButtonAction = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const page = window.location.href;

    if(formValidation.hasError === true) {
      setFormErrors(formValidation);
      return false;
    } else {
      setFormErrors({});
    }

    const model = {name, email, cvUrl, details, jobId, jobName, jobUrl: page};
    ApiService.sendJobApplicationMessage(model)
      .then(() => {
        setName('');
        setEmail('');
        setCvUrl('');
        setDetails('');
        createInformationalNotification(null, translationsObject.formSuccessText)
      })
      .catch(() => createErrorNotification(null, translationsObject.formErrorText));
  }

  return (
    <JobApplicationFormContainer>
      <FormTitleText>{translationsObject.title}</FormTitleText>
      <FormContentContainer>
        <FormTextField
          variant={'filled'}
          label={translationsObject.nameFieldLabel}
          placeholder={translationsObject.nameFieldPlaceholder}
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={(formErrors.nameError || '').length > 0}
          helperText={formErrors.nameError}
        />
        <FormTextField
          variant={'filled'}
          label={translationsObject.emailFieldLabel}
          placeholder={'example@gmail.com'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={(formErrors.emailError || '').length > 0}
          helperText={formErrors.emailError}
        />
        <FormTextField
          variant={'filled'}
          label={translationsObject.cvFieldLabel}
          placeholder={translationsObject.cvFieldPlaceholder}
          value={cvUrl}
          onChange={(e) => setCvUrl(e.target.value)}
          error={(formErrors.cvUrlError || '').length > 0}
          helperText={formErrors.cvUrlError}
        />
        <FormTextField
          multiline rows={3}
          variant={'filled'}
          label={translationsObject.detailsFieldLabel}
          placeholder={translationsObject.detailsFieldPlaceholder}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          error={(formErrors.detailsError || '').length > 0}
          helperText={formErrors.detailsError}
        />

        <DefaultButton
          color={'#5468E7'}
          textColor={'white'}
          roundness={5}
          verticalPadding={15}
          to={''}
          onClick={handleButtonAction}
        >
          {translationsObject.buttonText}
        </DefaultButton>
      </FormContentContainer>
    </JobApplicationFormContainer>
  );
};

export default JobApplicationForm;
