import styled from 'styled-components';
import {Link} from "react-router-dom";

const sectionSpacing = 80;

export const ComponentContainer = styled.div`
	background-color: #00113B;
	color: #748292;
`;

export const ContentContainer = styled.div`
  display: grid;
	grid-row-gap: ${sectionSpacing}px;
	padding: ${sectionSpacing}px 0;
`;

export const DisclaimerText = styled.div``;

export const LogoAndLinksWrapper = styled.div`
  display: grid;
	grid-template-columns: 1fr 1fr;
	
	img {
		max-width: 170px;
	}

	@media all and ${({theme: {device}}) => device.sm} {
		grid-template-columns: 1fr;
		grid-row-gap: ${sectionSpacing}px;
	}
`;

export const LinkSectionsWrapper = styled.div`
  display: grid;
	grid-template-columns: repeat(${props => props.categories}, 1fr);

	@media all and ${({theme: {device}}) => device.sm} {
		grid-template-columns: repeat(${props => props.categories}, max-content);
		grid-column-gap: ${sectionSpacing}px;
	}

	@media all and ${({theme: {device}}) => device.xs} {
		grid-template-columns: repeat(1, max-content);
		grid-row-gap: ${sectionSpacing}px;
	}
`;

export const CategoryContainer = styled.div`
	display: grid;
	grid-row-gap: 30px;
	grid-template-rows: max-content 1fr;
`;

export const CategoryTitle = styled.div`
  font-size: 25px;
	font-weight: 500;
	color: white;
`;

export const CategoryItemsWrapper = styled.div`
  display: grid;
	grid-row-gap: 15px;
	grid-template-rows: repeat(${props => props.numberOfItems}, min-content);
	align-items: start;
`;
export const CategoryItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const ItemLink = styled(Link)`
 &, &:hover, &:active, &:visited {
	 color: #748292;
	 text-decoration: none;
 }
`;

export const NewLinkBadge = styled.span`
	color: white;
	background-color: #1199FA;
	border-radius: 5px;
	text-transform: uppercase;
	margin-left: 5px;
	padding: 4px 10px;
	
	font-size: 11px;
`;

export const CopyrightBarWrapper = styled.div`
  background-color: #22314E;
	padding: 15px 20px;
`;
