import styled from "styled-components";

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 60px;
`;

export const SectionTitle = styled.div`
	position: relative;
	text-align: center;
	font-size: 50px;
	
	&:after {
    content: "";
    display: block;
    position: relative;
		width: 100px;

    margin: 10px auto 0;
    border-bottom: 2px solid #1875F0
  }
`;

export const CategoryFilterWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const PosItemsWrapper = styled.div`
  display: flex;
	justify-content: center;
	flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 40px;
`;

