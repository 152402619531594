import styled from "styled-components";
import {BaseButton} from "../../UI/Buttons/DefaultButton";

export const SectionContainer = styled.div`
	background-color: #fff;
	box-shadow: rgba(167, 167, 167, 0.15) 0px -1px 10px 1px;
	border-radius: 40px;
	padding: 120px 150px;

	@media all and ${({theme: {device}}) => device.sm} {
    padding: 100px 50px;
  }
	
	@media all and ${({theme: {device}}) => device.xs} {
    padding: 60px 40px;
  }
`;

export const ContentWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 250px;

	@media all and ${({theme: {device}}) => device.md} {
		grid-template-columns: 1fr;
		grid-row-gap: 50px;
	}

	@media all and ${({theme: {device}}) => device.xs} {
		//padding: 40px 20px;
	}
`;

export const LeftContentContainer = styled.div`
  display: grid;
	grid-row-gap: 40px;
`;

export const ContactInformationWrapper = styled.div`
  display: grid;
	grid-row-gap: 30px;
`;

export const ContactInformationItem = styled.div`
	display: grid;
	grid-column-gap: 30px;
	grid-template-columns: max-content 1fr;
	align-items: center;
	font-size: 17px;
`;

export const ContactInformationItemIcon = styled.div`
	background-image: url(${props => props.icon});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	
	width: 30px;
	height: 30px;
`;

export const RightContentContainer = styled.div`
  display: grid;
	grid-row-gap: 20px;
	
	${BaseButton} {
		padding: 15px 40px;
	}
`;

export const SectionSubTitle = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
  color: #1565D8;
	font-size: 20px;
	
	span {
		margin-right: 10px;
		height: 1px;
		width: 50px;
		background-color: #1565D8;
	}
	
	img {
		margin-left: 10px;
	}
	
	margin-bottom: 20px;
`;

export const SectionTitle = styled.div`
	color: #183B56;
	font-size: 52px;
	max-width: 376px;
	margin-bottom: 40px;

	@media all and ${({theme: {device}}) => device.md} {
		max-width: initial;
	}

	@media all and ${({theme: {device}}) => device.sm} {
		font-size: 36px;
	}
	
	@media all and ${({theme: {device}}) => device.xs} {
		font-size: 29px;
	}
`;

export const SectionDescription = styled.div`
	color: #5A7184;
	font-size: 17px;
`;

export const SectionText = styled.div`
	margin-bottom: 40px;
	color: white;
	font-size: 48px;

	@media all and ${({theme: {device}}) => device.xs} {
		font-size: 32px;
	}
`;
