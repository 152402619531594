import styled from "styled-components";

export const SectionTitle = styled.div`
  font-size: 59px;
	line-height: 74px;
	
	img {
		margin-left: 20px;
		height: 40px;
	}
`;

export const SectionItemsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 95px;
	grid-column-gap: 85px;
	
	@media all and ${({theme: {device}}) => device.md} {
		grid-template-columns: repeat(2, 1fr);
	}
	
	@media all and ${({theme: {device}}) => device.xs} {
		grid-template-columns: repeat(1, 1fr);
	}
`;

export const ItemContainer = styled.div`
  display: grid;
	grid-row-gap: 20px;
	border-bottom: 1px solid #707070;
	padding-bottom: 30px;
	grid-template-rows: max-content 1fr;
`;

export const ItemFirstRowContainer = styled.div`
  display: flex;
	column-gap: 20px;
	align-items: center;
`;


export const ItemTitle = styled.div`
  font-size: 24px;
	line-height: 29px;
`;

export const ItemDescription = styled.div`
  font-size: 16px;
	line-height: 28px;
	color: #81838C;
`;

export const ItemLogo = styled.div`
	display: block;
	padding: 30px;
	border-radius: 100%;
	background-color: #416FF4;
	box-shadow: 5px 5px 14px rgba(65, 111, 244, 0.23);
	
	background-image: url(${props => props.icon});
	background-repeat: no-repeat;
	background-position: center;
`;
