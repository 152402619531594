import React, {useContext} from 'react';
import {LocalisationContext} from "../../../../../library/Providers/SharedProviders/LocalisationProvider";
import JobCategoryItem from "../../../components/JobCategoryItem";

import {SectionContainer, SectionTitle, CategoryItemsWrapper} from "./styles";

const JobOpeningsSection = ({jobsGroupedByCategory}) => {
  const {translations} = useContext(LocalisationContext);
  const translationsObject = translations.staticScreens.careerScreen.jobOpenings;

  return (
    <SectionContainer>
      <SectionTitle>{translationsObject.title}</SectionTitle>
      <CategoryItemsWrapper>
        {jobsGroupedByCategory.map((jobCategory, index) => (
          <JobCategoryItem key={index} category={jobCategory.category} items={jobCategory.items} />
        ))}
      </CategoryItemsWrapper>
    </SectionContainer>
  );
};

export default JobOpeningsSection;
