import styled from 'styled-components';

export const SectionContainer = styled.div`
  display: grid;
  grid-row-gap: 100px;
`;

export const CategoryItemsWrapper = styled.div`
	display: grid;
	grid-row-gap: 70px;
`;

export const SectionTitle = styled.div`
  color: #001738;
	font-size: 60px;
  line-height: 1.1;
  text-align: center;
`;
