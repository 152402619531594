import React, {useContext} from 'react';

import {CONTACT_US_ROUTE} from "../../../../library/Navigation/NavigationConstants";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import ContentSizeLimiter from "../../../../components/UI/Containers/ContentSizeLimiter";
import DefaultButton from "../../../../components/UI/Buttons/DefaultButton";

import {
	ButtonsWrapper,
	ContentWrapper,
	ImageContainer,
	SectionContainer,
	SectionContentItemsWrapper,
	SectionDescription,
	SectionTitle
} from "./styles";

const HeroSection = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.ecommerceScreen.hero;

  return (
    <SectionContainer>
	    <ContentSizeLimiter>
		    <ContentWrapper>
			    <ImageContainer />
			    <SectionContentItemsWrapper>
				    <SectionTitle dangerouslySetInnerHTML={{ __html: translationsObject.title}} />
				    <SectionDescription>{translationsObject.description}</SectionDescription>
				    <ButtonsWrapper>
					    <DefaultButton mode={'outlined'} to={''}>{translationsObject.moreInfoButtonText}</DefaultButton>
					    <DefaultButton color={'#2051E2'} textColor={'white'} to={CONTACT_US_ROUTE}>{translationsObject.contactButtonText}</DefaultButton>
				    </ButtonsWrapper>
			    </SectionContentItemsWrapper>
		    </ContentWrapper>
	    </ContentSizeLimiter>
    </SectionContainer>
  );
};

export default HeroSection;
