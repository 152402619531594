import styled from 'styled-components';

export const PageContainer = styled.div`
  background-color: #F7F9FB;
`;

export const JobOpeningsSectionWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
`;
