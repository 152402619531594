import React, {useContext} from 'react';
import ContentSizeLimiter from "../../../../components/UI/Containers/ContentSizeLimiter";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";
import DefaultButton from "../../../../components/UI/Buttons/DefaultButton";
import {ABOUT_US_ROUTE, CONTACT_US_ROUTE} from "../../../../library/Navigation/NavigationConstants";

import {
	ContentContainer,
	ContentItemsWrapper,
	DescriptionText,
	SectionContainer,
	TitleText, StatWrapper, StatItemContainer, StatItemValue, StatItemText, ButtonsWrapper, TextAndButtonsWrapper
} from "./styles";

const HeroSection = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.partners.hero;

  return (
    <SectionContainer>
			<ContentSizeLimiter>
				<ContentContainer>
					<ContentItemsWrapper>
						<TextAndButtonsWrapper>
							<div>
								<TitleText>{translationsObject.title}</TitleText>
							</div>
							<DescriptionText>{translationsObject.description}</DescriptionText>
							<ButtonsWrapper>
								<DefaultButton to={ABOUT_US_ROUTE} color={'#1565D8'} textColor={'white'}>
									{translationsObject.aboutUsButtonText}
								</DefaultButton>
								<DefaultButton to={CONTACT_US_ROUTE} color={'#1565D8'} textColor={'white'} mode={'outlined'}>
									{translationsObject.contactUsButtonText}
								</DefaultButton>
							</ButtonsWrapper>
						</TextAndButtonsWrapper>
						<StatWrapper>
							<StatItemContainer>
								<StatItemValue>240+</StatItemValue>
								<StatItemText>{translationsObject.storesText}</StatItemText>
							</StatItemContainer>
							<StatItemContainer>
								<StatItemValue>2648</StatItemValue>
								<StatItemText>{translationsObject.customersText}</StatItemText>
							</StatItemContainer>
							<StatItemContainer>
								<StatItemValue>5<span>+</span></StatItemValue>
								<StatItemText>{translationsObject.experienceText}</StatItemText>
							</StatItemContainer>
						</StatWrapper>
					</ContentItemsWrapper>
				</ContentContainer>
			</ContentSizeLimiter>
    </SectionContainer>
  );
};

export default HeroSection;
