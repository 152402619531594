import React, {useMemo} from 'react';
import moment from "moment";
import {ArrowForward} from "@material-ui/icons";

import JobDetailItem from "./components/JobDetailItem";
import {JobItemContainer, IconAndTitleContainer, JobItemIcon, JobTitleText, JobDetailsWrapper} from "./styles";

const JobItem = ({job}) => {
  const dateString = useMemo(
    () => moment(job.expirationDate, "DD/MM/YYYY").format('ll'),
    [job]
  );

  return (
    <JobItemContainer>
      <IconAndTitleContainer>
        <JobItemIcon data-category={job.category} data-sub-category={job.subCategory}>{job.titleAbbreviation}</JobItemIcon>
        <JobTitleText>{job.title}</JobTitleText>
      </IconAndTitleContainer>
      <JobDetailsWrapper>
        <JobDetailItem text={job.type} type={'job-type'} />
        <JobDetailItem text={job.location} type={'job-location'} />
        <JobDetailItem text={dateString} type={'job-expiration-date'} />
      </JobDetailsWrapper>
      <div>
        <ArrowForward />
      </div>
    </JobItemContainer>
  );
};

export default JobItem;
