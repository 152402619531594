import React, {useState} from 'react';

import CompanyLogo from "../../../../../../components/Atomic/Branding/CompanyLogo";
import {HOMEPAGE_ROUTE} from "../../../../../Navigation/NavigationConstants";

import {
	HeaderContainer,
	LogoWrapper,
	MenuSectionsWrapper,
	StyledContentSizeLimiter
} from "./styles";

import MenuSection from "./components/MenuSection";
import ResponsiveMenu from "./components/ResponsiveMenu";
import useMenuItems from "./useMenuItems";
import {useScrollPosition} from "../../../../../Helpers/useScrollPosition";

const HEADER_TYPE_TO_LOGO_VARIANT = {
	default: 'primary',
	'dark-transparent': 'white'
}

const DefaultHeader = ({type = 'default'}) => {
	const [windowIsScrolled, setWindowIsScrolled] = useState(false);
	const items = useMenuItems();

	useScrollPosition(({currPos}) => {
		setWindowIsScrolled(currPos.y !== 0);
	}, [])

	return (
    <HeaderContainer data-header-type={type} data-scrolled={windowIsScrolled}>
	    <StyledContentSizeLimiter maxWidth={'lg'}>
		    <MenuSectionsWrapper>
					<LogoWrapper to={HOMEPAGE_ROUTE}>
						<CompanyLogo colorVariant={HEADER_TYPE_TO_LOGO_VARIANT[type]} />
					</LogoWrapper>
			    <ResponsiveMenu breakpoint={'sm'}>
				    <MenuSection items={items} />
			    </ResponsiveMenu>
		    </MenuSectionsWrapper>
	    </StyledContentSizeLimiter>
    </HeaderContainer>
  );
};

export default DefaultHeader;
