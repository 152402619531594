import styled from "styled-components";
import backgroundImage from './assets/hero-background-image.png';

export const SectionContainer = styled.div`
	background-color: #14161d;
	color: white;

	@media all and ${({theme: {device}}) => device.sm} {
		padding: 60px 0;
		background-image: none;
	}
`;

export const ContentWrapper = styled.div`
	display: grid;
	grid-template-columns: 5fr 4fr;
	grid-column-gap: 40px;
	justify-content: flex-end;
	
	@media all and ${({theme: {device}}) => device.md} {
    grid-template-columns: 1fr;
  }
`;

export const ImageContainer = styled.div`
  background-image: url(${backgroundImage});
  background-position: center left -20px;
  background-repeat: no-repeat;
  background-size: contain;

  @media all and ${({theme: {device}}) => device.md} {
    display: none;
  }
`;

export const SectionContentItemsWrapper = styled.div`
  display: grid;
	grid-row-gap: 35px;
	justify-items: start;
	justify-self: center;
  padding: 180px 0;

  @media all and ${({theme: {device}}) => device.md} {
    max-width: 75%;
    padding: 80px 0;
  }
	
  @media all and ${({theme: {device}}) => device.sm} {
    max-width: initial;
  }
`;

export const SectionTitle = styled.div`
	font-size: 48px;
	line-height: calc(62 / 48);
	
	@media all and ${({theme: {device}}) => device.md} {
		font-size: 41px;
	}
`;

export const SectionDescription = styled.div`
	font-size: 20px;
	line-height: 33px;
  color: #656B7A;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
	column-gap: 15px;
`;


