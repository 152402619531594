import React from 'react';

import JobDetailsHeader from "../JobDetailsHeader";
import DynamicContentRenderer from "../../../../../components/Layout/DynamicContentRenderer";
import JobApplicationForm from "../JobApplicationForm";
import ScreenHeaderWithBreadcrumbs
	from "../../../../../module/StaticPages/components/Layout/ScreenHeaderWithBreadcrumbs";

import {
	ContentContainer,
	FormWrapper,
	JobDetailsContainer,
	SectionItemContainer,
	SectionsWrapper,
	SectionTitle
} from "./styles";

const JobView = ({job}) => {
	const contentSections = job.contentSections;

  return (
	  <ScreenHeaderWithBreadcrumbs breadcrumbs={['Home', 'Career', job.title]}>
		  <ContentContainer>
			  <JobDetailsContainer>
				  <JobDetailsHeader job={job} />
				  <SectionsWrapper>
					  {contentSections.map((section, index) => (
						  <SectionItemContainer key={index}>
							  <SectionTitle>{section.title}</SectionTitle>
							  <DynamicContentRenderer content={section.content} />
						  </SectionItemContainer>
					  ))}
				  </SectionsWrapper>
			  </JobDetailsContainer>
			  <FormWrapper>
				  <JobApplicationForm jobId={job.id} jobName={job.title} />
			  </FormWrapper>
		  </ContentContainer>
	  </ScreenHeaderWithBreadcrumbs>
  );
};

export default JobView;
