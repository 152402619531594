import {
	EXTERNAL_COOKIE_NAME,
	MARKETING_COOKIE_NAME, PERFORMANCE_COOKIE_NAME,
	PREFERENCES_COOKIE_NAME,
	STATISTICS_COOKIE_NAME
} from "./CookieConstants";
import {useContext, useMemo} from "react";
import {LocalisationContext} from "../../../library/Providers/SharedProviders/LocalisationProvider";

export default function useOptions(props){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.sections.cookieBanner.tabs.details.options;

	const {cookiePreferences, onToggleCookiePreferences} = props;
	const toggleCallback = (cookieOption) => (e) => onToggleCookiePreferences(cookieOption, e);

	return useMemo(() => [
		{
			...translationsObject.necessary,
			disabled: true,
			checked: true,
		},
		{
			...translationsObject.preferences,
			checked: cookiePreferences[PREFERENCES_COOKIE_NAME],
			onChange: toggleCallback(PREFERENCES_COOKIE_NAME)
		},
		{
			...translationsObject.statistics,
			checked: cookiePreferences[STATISTICS_COOKIE_NAME],
			onChange: toggleCallback(STATISTICS_COOKIE_NAME)
		},
		{
			...translationsObject.performance,
			checked: cookiePreferences[PERFORMANCE_COOKIE_NAME],
			onChange: toggleCallback(PERFORMANCE_COOKIE_NAME)
		},
		{
			...translationsObject.marketing,
			checked: cookiePreferences[MARKETING_COOKIE_NAME],
			onChange: toggleCallback(MARKETING_COOKIE_NAME)
		},
		{
			...translationsObject.external,
			checked: cookiePreferences[EXTERNAL_COOKIE_NAME],
			onChange: toggleCallback(EXTERNAL_COOKIE_NAME)
		}
	], [cookiePreferences]);
}
