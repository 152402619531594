import styled from "styled-components";
import rightSectionImage from "./assets/right-section-background-image.png";
import {SectionWrapperWithBackgroundImage} from "../EcommerceScreen/styles";

export const SectionsWrapper = styled.div`
	display: grid;
	grid-row-gap: 170px;
	padding: 150px 0 0;
`;


export const RightSectionWrapper = styled(SectionWrapperWithBackgroundImage)`
  background-image: url(${rightSectionImage});
	background-position: center right;

	@media all and ${({theme: {device}}) => device.sm} {
		background-position: top right;
	}
`;
