import React, {useContext} from 'react';
import {LocalisationContext} from "../../../../../library/Providers/SharedProviders/LocalisationProvider";
import ContentSizeLimiter from "../../../../../components/UI/Containers/ContentSizeLimiter";

import {
  ContentContainer, ContentWrapper,
  SectionContainer,
  SectionDescription,
  SectionDescriptionsWrapper,
  SectionTitle
} from "./styles";

const HeroSection = () => {
  const {translations} = useContext(LocalisationContext);
  const translationsObject = translations.staticScreens.careerScreen.hero;

  return (
    <SectionContainer>
      <ContentSizeLimiter>
        <ContentWrapper>
          <ContentContainer>
            <SectionTitle>{translationsObject.title}</SectionTitle>
            <SectionDescriptionsWrapper>
              {translationsObject.descriptions.map((description, index) => (
                <SectionDescription>{description}</SectionDescription>
              ))}
            </SectionDescriptionsWrapper>
          </ContentContainer>
        </ContentWrapper>
      </ContentSizeLimiter>
    </SectionContainer>
  );
};

export default HeroSection;
