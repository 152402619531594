import React, {useMemo} from 'react';
import moment from "moment";
import JobDetailItem from "./components/JobDetailItem";

import {JobDetailItemsWrapper, JobDetailsHeaderContainer, JobTitleText} from "./styles";

const JobDetailsHeader = ({job}) => {
	const dateString = useMemo(
		() => moment(job.expirationDate, "DD/MM/YYYY").format('ll'),
		[job]
	);

  return (
	  <JobDetailsHeaderContainer>
		  <JobTitleText>{job.title}</JobTitleText>
		  <JobDetailItemsWrapper>
			  <JobDetailItem text={job.type} type={'job-type'} />
			  {job.hasOwnProperty('location') && (
				  <JobDetailItem text={job.location} type={'job-location'} />
			  )}
			  <JobDetailItem text={dateString} type={'job-expiration-date'} />
		  </JobDetailItemsWrapper>
	  </JobDetailsHeaderContainer>
  );
};

export default JobDetailsHeader;
