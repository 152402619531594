import styled from 'styled-components';

export const MenuSectionContainer = styled.div`
	display: grid;
	grid-template-columns: max-content auto;
	grid-column-gap: 40px;
	align-items: center;
`;

export const MenuItemsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(${props => props.numberOfItems}, max-content);
	grid-column-gap: 40px;
	
	@media all and ${({theme: {device}}) => device.md} {
    grid-column-gap: 35px;
  }
`;
