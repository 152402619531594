const req = require.context('./jobs', true, /.json$/);
const jobs = req.keys().map(req);

const jobsMappedById = {};
jobs.forEach(job => {
	jobsMappedById[job.id] = job;
});

module.exports = {
	jobs, jobsMappedById
}
