import styled from "styled-components";
import ContainerWithSkewBackground
	from "../../components/UI/Containers/ContainerWithSkewBackground";

import heroSectionBackground from "./assets/hero-section-background.png";

export const ScreenContainer = styled.div`
	background-color: #F2F3F4;
`;

export const PosSectionWrapper = styled.div`
	display: grid;
	grid-row-gap: 80px;
  padding: 80px 0;
  background-color: white;
`;

export const HeroSectionWrapper = styled.div`
  background-color: #1A1A1A;
  background-image: url(${heroSectionBackground});
  min-height: 500px;
`;

export const ContactFormContainer = styled(ContainerWithSkewBackground)`
	padding: 70px;
	
	@media all and ${({theme: {device}}) => device.xs} {
		padding: 70px 30px;
	}
	
	&::after {
		background-color: #00113B;
	}
`;
