import React from 'react';
import StaticPageContainerWithHeaderSpaceFix
	from "../../components/Layout/StaticPageContainerWithHeaderSpaceFix";
import ContentSizeLimiter from "../../components/UI/Containers/ContentSizeLimiter";

import HeroSection from "./components/HeroSection";
import PapelFacesSection from "./components/PapelFacesSection";

import {RightSectionWrapper, SectionsWrapper} from "./styles";
import BenefitsSection from "./components/BenefitsSection";
import PaymentsInASnapSection from "./components/PaymentsInASnapSection";

const AboutUsScreen = () => {
  return (
	  <StaticPageContainerWithHeaderSpaceFix>
		  <HeroSection />
		  <SectionsWrapper>
			  <ContentSizeLimiter>
			    <BenefitsSection />
			  </ContentSizeLimiter>

			  <RightSectionWrapper>
				  <PaymentsInASnapSection />
			  </RightSectionWrapper>

			  <ContentSizeLimiter>
				  <PapelFacesSection />
			  </ContentSizeLimiter>
		  </SectionsWrapper>
	  </StaticPageContainerWithHeaderSpaceFix>
  );
};

export default AboutUsScreen;
