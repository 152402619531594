import React, {useContext} from 'react';
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";
import SectionWithSideImage
	from "../../../../module/StaticPages/components/Layout/Sections/SectionWithSideImage";
import ContentForSectionWithSideImage from "../ContentForSectionWithSideImage";
import sideImage from './../../assets/papel-pos-image.png'

import DefaultButton from "../../../../components/UI/Buttons/DefaultButton";
import {CONTACT_US_ROUTE} from "../../../../library/Navigation/NavigationConstants";

import {ButtonsWrapper, SectionContainer} from "./styles";

const PosPresentationSection = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.ecommerceScreen.posSection;

  return (
    <SectionContainer>
	    <SectionWithSideImage centeredVertically image={sideImage} isReversed={true}>
		    <ContentForSectionWithSideImage
			    title={translationsObject.title}
			    descriptions={translationsObject.descriptions}
			    subTitle={translationsObject.subTitle}
		    />
		    <ButtonsWrapper>
			    <DefaultButton mode={'outlined'} color={'#2051E2'} to={''}>{translationsObject.moreInfoButtonText}</DefaultButton>
			    <DefaultButton color={'#2051E2'} textColor={'white'} to={CONTACT_US_ROUTE}>{translationsObject.contactButtonText}</DefaultButton>
		    </ButtonsWrapper>
	    </SectionWithSideImage>
    </SectionContainer>
  );
};

export default PosPresentationSection;
