import styled from 'styled-components';

export const JobDetailsHeaderContainer = styled.div`
  display: grid;
	grid-row-gap: 30px;

  @media all and ${({theme: {device}}) => device.sm} {
    grid-row-gap: 40px;
  }
`;

export const JobTitleText = styled.div`
  color: #1565D8;
	font-size: 50px;
	line-height: 1.1;

  @media all and ${({theme: {device}}) => device.sm} {
    font-size: 42px;
  }
`;

export const JobDetailItemsWrapper = styled.div`
  display: flex;
	column-gap: 50px;

  @media all and ${({theme: {device}}) => device.sm} {
	  display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`;
