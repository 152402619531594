import {useContext, useMemo} from "react";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import transparencyIcon from './assets/transparency-icon.png';
import professionalismIcon from './assets/professionalism-icon.png';
import humanSideIcon from './assets/human-side-icon.png';

export default function useItems(){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.partners.whatWeOffer.items;

	return useMemo(() => [
		{
			...translationsObject.transparency,
			icon: transparencyIcon
		},
		{
			...translationsObject.professionalism,
			icon: professionalismIcon
		},
		{
			...translationsObject.humanSide,
			icon: humanSideIcon
		}
	], [translationsObject]);
}
