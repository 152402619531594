import styled from "styled-components";

export const CategoryContainer = styled.div`
  display: grid;
  grid-row-gap: 30px;
`;

export const TitleText = styled.div`
  color: #001738;
	font-size: 32px;
`;

export const ItemsWrapper = styled.div`
  display: grid;
	grid-row-gap: 20px;
	
	a, a:visited, a:active, a:hover {
    text-decoration: none;
	}
`;
