import styled from "styled-components";

export const CategoryFilterContainer = styled.div`
  display: flex;
  column-gap: 30px;

  @media all and ${({theme: {device}}) => device.sm} {
    column-gap: 15px;
  }
`;

export const CategoryItem = styled.div`
  cursor: pointer;
	padding: 0 3px 5px;
	border-bottom: 2px solid transparent;
	
	&[data-selected="true"]{
		color: #1875F0;
		border-bottom-color: #1875F0;
	}
`;
