import styled from "styled-components";

export const FooterCopyrightBarContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: #566D9A;

	@media all and ${({theme: {device}}) => device.sm} {
		flex-direction: column-reverse;
	}
`;

export const CopyrightText = styled.div`
	display: flex;
	flex-grow: 1;
	font-size: 15px;
	font-weight: 400;

	@media all and ${({theme: {device}}) => device.sm} {
		margin-top: 20px;
	}
`;

export const SocialIconsContainer = styled.div`
  display: flex;
`;

export const SocialIconItem = styled.a`
  display: flex;
	margin-left: 10px;
	color: inherit;
`;
