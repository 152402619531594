import React from 'react';
import {makeStyles, TextField} from "@material-ui/core";

const useStyles = makeStyles({
	underline: {
		"&": {
			borderRadius: 10,
			backgroundColor: '#F6F6F6',
			color: '#000',
			fontSize: 18
		},
		"&&:before": {
			borderBottom: "none"
		},
		"&&:after": {
			borderBottom: "none"
		}
	}
});

const useHelperStyles = makeStyles({
	root: {
		marginLeft: 0
	},
});

const FormTextField = ({...props}) => {
	const classes = useStyles();
	const helperClasses = useHelperStyles();

	const hasError = (props.helperText || '').length > 0;
	return (
	  <TextField
		  variant={'filled'}
			FormHelperTextProps={{classes: helperClasses}}
			InputProps={{ classes }}
			error={hasError}
			{...props}
	  />
  );
};

export default FormTextField;
