import styled from "styled-components";

const safeGetPositionInPercents = (position) => Math.min(Math.max(position, 0), 100);
const calculatePositionInPercents = (position) => 100 - safeGetPositionInPercents(position);
const computeRightPosition = (position) => calculatePositionInPercents(position);
const computeLeftPosition = (position) => calculatePositionInPercents(position);

const ContainerWithSkewBackground = styled.div`
	position: relative;
	
	& > * {
		position: relative;
		z-index: 1;
	}
	
	&::after {
		z-index: 0;
		
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: black;
		font-size: 0;

		clip-path: polygon(
			0 ${props => computeLeftPosition(props.left)}%, 
			100% ${props => computeRightPosition(props.right)}%, 
			100% calc(100% + 0.3px), 
			0 calc(100% + 0.3px)
		);
	}
`;

export default ContainerWithSkewBackground;
