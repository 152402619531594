import React, {useMemo} from 'react';
import {KeyboardArrowDown} from "@material-ui/icons";

import {
	MenuItemContainer,
	MenuItemLink,
	MenuItemText,
	SubMenuContentContainer,
	SubMenuWrapper
} from "./styles";

const MenuItem = ({text, to, target, hasSubMenu = false, items}) => {
	const menuTextProps = useMemo(() => {
		if(hasSubMenu === true) {
			return {};
		}

		return {
			to: to,
			target: target
		}
	}, [hasSubMenu, to]);

	const MenuItemLinkComponent = hasSubMenu === false ? MenuItemLink : React.Fragment;

  return (
    <MenuItemContainer withSubMenu={hasSubMenu}>
	    <MenuItemText>
		    <MenuItemLinkComponent {...menuTextProps}>{text}</MenuItemLinkComponent>
		    {hasSubMenu === true && (<KeyboardArrowDown fontSize={'inherit'} />)}
	    </MenuItemText>

	    {hasSubMenu === true && (
		    <SubMenuWrapper>
			    <SubMenuContentContainer>
				    {items.map((item, index) => (
					    <MenuItemText key={index}>
					      <MenuItemLink to={item.to} target={item.target}>{item.text}</MenuItemLink>
					    </MenuItemText>
				    ))}
			    </SubMenuContentContainer>
		    </SubMenuWrapper>
	    )}
    </MenuItemContainer>
  );
};

export default MenuItem;
