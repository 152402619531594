import {useContext, useMemo} from "react";
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import payWithBalanceIcon from './assets/user-icon.png';
import payWithHiddenCardIcon from './assets/send-icon.png';
import payWitWireTransferIcon from './assets/chat-icon.png';
import integrationIcon from './assets/stack-icon.png';
import scaleUpIcon from './assets/lightning-icon.png';

export default function useBenefitItems(){
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.aboutUs.benefitsSection.items;

	return useMemo(() => [
		{
			icon: payWithBalanceIcon,
			...translationsObject.payWithBalance
		},
		{
			icon: payWithHiddenCardIcon,
			...translationsObject.payWithHiddenCard
		},
		{
			icon: payWitWireTransferIcon,
			...translationsObject.payWitWireTransfer
		},
		{
			icon: integrationIcon,
			...translationsObject.integration
		},
		{
			icon: scaleUpIcon,
			...translationsObject.scaleUp
		}
	], [translationsObject]);
}
