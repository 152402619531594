import {
	ABOUT_US_ROUTE,
	CAREER_ROUTE,
	CONTACT_US_ROUTE,
	COOKIE_POLICY_ROUTE,
	ECOMMERCE_ROUTE,
	FAQ_ROUTE,
	POS_ROUTE, PRIVACY_POLICY_JOB_APPLICANTS_ROUTE,
	PRIVACY_POLICY_ROUTE, SECURITY_ROUTE, TECHNOLOGY_ROUTE,
	TOS_ROUTE
} from "../../../../../Navigation/NavigationConstants";
import Environment from "../../../../../Environment";

export default function useLinks() {

	return [
		{
			category: 'Company',
			items: [
				{
					title: 'About Us',
					to: ABOUT_US_ROUTE,
				},
				{
					title: 'Career',
					to:  CAREER_ROUTE,
				},
				{
					title: 'Contact Us',
					to: CONTACT_US_ROUTE,
				},
				{
					title: 'FAQ',
					to: FAQ_ROUTE,
				},
				{
					title: 'Blog',
					to: {pathname: Environment.blogURL},
					target:'_parent'
				},
			]
		},
		{
			category: 'Solutions',
			items: [
				// {
				// 	title: 'POS',
				// 	to: POS_ROUTE,
				// },
				{
					title: 'E-Commerce',
					to: ECOMMERCE_ROUTE,
				},
			]
		},
		{
			category: "Tech",
			items: [
				{
					title: 'Technology',
					to: TECHNOLOGY_ROUTE,
				},
				{
					title: 'Security',
					to: SECURITY_ROUTE,
				},

			]
		},
		{
			category: 'Policies',
			items: [
				{
					title: 'Terms and Conditions',
					to: TOS_ROUTE,
				},
				{
					title: 'Privacy Policy',
					to: PRIVACY_POLICY_ROUTE,
				},
				{
					title: 'Cookie Policy',
					to: COOKIE_POLICY_ROUTE,
				},
				{
					title: 'Privacy Policy For Job Applicants',
					to: PRIVACY_POLICY_JOB_APPLICANTS_ROUTE,
				},
			]
		}
	]

}
