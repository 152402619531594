import React from 'react';

import {
	ComponentContainer,
	ComponentImageWrapper,
	ComponentContentWrapper,
	ComponentImage,
} from "./components/Styled";

export default function SectionWithSideImage({children, image, isReversed, centeredVertically = false}){
	return (
		<ComponentContainer isReversed={isReversed} centeredVertically={centeredVertically}>
			<ComponentContentWrapper isReversed={isReversed} centeredVertically={centeredVertically}>
				{children}
			</ComponentContentWrapper>
			<ComponentImageWrapper isReversed={isReversed} centeredVertically={centeredVertically}>
				<ComponentImage src={image} />
			</ComponentImageWrapper>
		</ComponentContainer>
	);
};
