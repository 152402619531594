import intl from "react-intl-universal";
import {APP_LANGUAGE_STORAGE_KEY, TRANSLATIONS} from "./LocalizationConstants";
import Environment from "../Environment";

export default function determineInitialLocale(){
	let currentLocale = intl.determineLocale({
		urlLocaleKey: APP_LANGUAGE_STORAGE_KEY,
		cookieLocaleKey: APP_LANGUAGE_STORAGE_KEY,
		localStorageLocaleKey: APP_LANGUAGE_STORAGE_KEY
	});

	if (TRANSLATIONS.hasOwnProperty(currentLocale) === false) {
		currentLocale = Environment.defaultLanguage;
	}

	return currentLocale;
}

/* eslint-disable */
String.prototype.replaceParameters =
	String.prototype.replaceParameters ||
	function () {
		'use strict';
		let str = this.toString();
		if (arguments.length) {
			const typeOfArguments = typeof arguments[0];
			let key;
			const args =
				typeOfArguments === 'string' || typeOfArguments === 'number'
					? Array.prototype.slice.call(arguments)
					: arguments[0];

			for (key in args) {
				str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
			}
		}

		return str;
	};
/* eslint-enable */
