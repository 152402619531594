import styled from "styled-components";
import leftSectionBackgroundImage from './assets/left-section-background-image.png';
import rightSectionBackgroundImage from './assets/right-section-background-image.png';

export const HeroSectionsContainer = styled.div`
	padding-top: 60px;
`;

export const SectionItemsWrapper = styled.div`
  display: grid;
	grid-row-gap: 150px;
	padding: 100px 0;
`;

export const AppPresentationItemsWrapper = styled.div`
	display: grid;
	grid-row-gap: 150px;
	padding: 100px 0;
	
	@media all and ${({theme: {device}}) => device.sm} {
		grid-row-gap: 100px;
	}
`;

export const SectionWrapperWithBackgroundImage = styled.div`
	background-repeat: no-repeat;
	background-size: contain;
`;

export const RightSectionWrapper = styled(SectionWrapperWithBackgroundImage)`
  background-image: url(${leftSectionBackgroundImage});
	background-position: center left;

	@media all and ${({theme: {device}}) => device.sm} {
		background-position: top left;
	}
`;

export const LeftSectionWrapper = styled(SectionWrapperWithBackgroundImage)`
	background-image: url(${rightSectionBackgroundImage});
	background-position: center right;

	@media all and ${({theme: {device}}) => device.sm} {
		background-position: top right;
	}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
	column-gap: 15px;
	margin-top: 25px;
`;
