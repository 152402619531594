import styled from "styled-components";

export const ItemContainer = styled.div`
	display: grid;
	grid-column-gap: 40px;
	grid-row-gap: 40px;
	padding: 30px;
	border: 1px solid #E6E6E6;
	border-radius: 6px;
	
	text-align: center;
`;

export const ItemHeaderWrapper = styled.div`
  display: flex;
	justify-content: space-between;
`;

export const ImageWrapper = styled.div`
  justify-content: center;
`;

export const NewItemContainer = styled.div`
	display: flex;
	align-items: center;
  background-color: #1875F0;
	border-radius: 4px;
  padding: 2px 10px;

  color: white;
	font-size: 13px;
`;

export const ItemNameText = styled.div`
  font-size: 27px;
`;

export const ItemSizeText = styled.div`
  font-size: 15px;
	color: #959595
`;

export const ItemFooterWrapper = styled.div`
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
`;

export const StarRatingWrapper = styled.div`
  color: #F9A72D;
	font-size: 25px;
	
	& > *:last-of-type {
		color: #E7E5E5;
	}
`;
