import styled from "styled-components";

export const SectionContainer = styled.div`
	display: grid;
	grid-row-gap: 40px;
	color: #fff;
`;

export const TitleText = styled.div`
  font-size: 50px;
`;

export const LogosWrapper = styled.div`
  display: flex;
	grid-column-gap: 80px;
	align-items: start;

	@media all and ${({theme: {device}}) => device.md} {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 80px;
	}
`;

export const LogoItem = styled.img`
	height: 50px;
`;


