import React, {useContext} from 'react';
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";
import {CONTACT_US_ROUTE} from "../../../../library/Navigation/NavigationConstants";

import DefaultButton from "../../../../components/UI/Buttons/DefaultButton";
import ContentSizeLimiter from "../../../../components/UI/Containers/ContentSizeLimiter";
import ContentForSectionWithSideImage
	from "../../../EcommerceScreen/components/ContentForSectionWithSideImage";
import SectionWithSideImage from "../../../../components/Layout/Sections/SectionWithSideImage";

import image from './assets/payments-section-image.png';


const PaymentsInASnapSection = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.aboutUs.paymentsSection;
  return (
	  <ContentSizeLimiter>
		  <SectionWithSideImage centeredVertically image={image} >
			  <ContentForSectionWithSideImage
				  {...translationsObject}
			  />
			  <div>
				  <DefaultButton
					  color={'#F2B604'}
					  to={CONTACT_US_ROUTE}
				  >
					  {translationsObject.buttonText}
				  </DefaultButton>
			  </div>
		  </SectionWithSideImage>
	  </ContentSizeLimiter>
  );
};

export default PaymentsInASnapSection;
