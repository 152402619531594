import styled from "styled-components";
const itemSpaceLeft = 20;

export const SectionContainer = styled.div`
	color: #fff;
`;

export const ContentWrapper = styled.div`
  display: grid;
	grid-row-gap: 80px;
`;

export const SectionTitle = styled.div`
  font-size: 52px;
`;

export const ItemsWrapper = styled.div`
  display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 80px;
	align-items: start;

	@media all and ${({theme: {device}}) => device.md} {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: 80px;
	}

	@media all and ${({theme: {device}}) => device.xs} {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 80px;
	}
`;

export const ItemContainer = styled.div`
	display: grid;
	grid-template-rows: 40px max-content 1fr;
	grid-row-gap: 15px;
`;

export const ItemTitle = styled.div`
	font-size: 22px;
	line-height: 22px;
	padding-left: ${itemSpaceLeft}px;
	border-left: 1px solid #0C7794;
`;

export const ItemDescription = styled.div`
  color: #A7B7C7;
	font-size: 16px;
	padding-left: ${itemSpaceLeft}px;
	line-height: 30px;
`;

export const ItemIcon = styled.div`
  background-image: url(${props => props.icon});
	background-size: contain;
	background-position: center;
	height: 40px;
	width: 40px;
	margin-left: ${itemSpaceLeft}px;
`;



