import React, {useContext, useMemo} from 'react';
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import JobItem from "../JobItem";
import {CategoryContainer, ItemsWrapper, TitleText} from "./styles";
import {Link} from "react-router-dom";
import {getCareerJobLink} from "../../../../library/Navigation/NavigationConstants";

const JobCategoryItem = ({category, items}) => {
	const {translations} = useContext(LocalisationContext);
	const categoryTitleTranslations = translations.common.careerCategories;
	const categoryText = useMemo(
		() => categoryTitleTranslations.hasOwnProperty(category) ? categoryTitleTranslations[category] : category,
		[categoryTitleTranslations, category]
	);

  return (
    <CategoryContainer>
	    <TitleText>{categoryText}</TitleText>
	    <ItemsWrapper>
		    {items.map((item, index) => (
		    	<Link to={getCareerJobLink(item.id)}>
				    <JobItem key={index} job={item} />
			    </Link>
		    ))}
	    </ItemsWrapper>
    </CategoryContainer>
  );
};

export default JobCategoryItem;
