import {useMediaQuery, useTheme} from "@material-ui/core";
import {useMemo} from "react";

export default function useHeroSectionSkewValues(){
	const theme = useTheme();
	const isUnderMedium = useMediaQuery(theme.breakpoints.down('md'));
	const isUnderLarge = useMediaQuery(theme.breakpoints.down('lg'));

	const VALUE_MAPPINGS = {
		sm: {
			right: 50,
			left: 0
		},
		md: {
			right: 60,
			left: 0
		},
		default: {
			right: 60,
			left: 0
		},
	}

	return useMemo(() => {
		switch(true){
			case isUnderMedium:
				return VALUE_MAPPINGS['sm'];
			case isUnderLarge:
				return VALUE_MAPPINGS['md'];
			default:
				return VALUE_MAPPINGS['default'];
		}
	}, [isUnderLarge, isUnderMedium, VALUE_MAPPINGS]);
}
