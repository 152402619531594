import React from 'react';
import CompanyLogo from "../../components/Atomic/Branding/CompanyLogo";

import {
    PageWrapper,
    LogoContainer,
    ImageContainer,
    ContentWrapper,
    ComingSoonLargeText,
    ComingSoonSmallText
} from "./styles";

const ComingSoonScreen = () => {
    return (
        <PageWrapper>
            <LogoContainer><CompanyLogo /></LogoContainer>
            <ImageContainer />
            <ContentWrapper>
                <ComingSoonLargeText>This product is under construction</ComingSoonLargeText>
                <ComingSoonSmallText>We're working on it</ComingSoonSmallText>
            </ContentWrapper>
        </PageWrapper>
    );
};

export default ComingSoonScreen;
