import styled from 'styled-components';

export const ComponentContainer = styled.div`
	display: grid;
	grid-column-gap: 120px;
	grid-row-gap: 40px;
	grid-template-columns: 1fr 1fr;

	direction: ${props => props.isReversed === true ? 'rtl' : 'ltr'};
	
	@media all and ${({theme: {device}}) => device.md} {
		grid-template-columns: 3fr 2fr;
	}

	@media all and ${({theme: {device}}) => device.sm} {
		direction: ltr;
		grid-template-columns: 1fr;
	}
`;

export const ComponentContentWrapper = styled.div`
	direction: ltr;
	display: grid;
	align-items: ${props => props.centeredVertically === true ? 'center' : 'start'};


	@media all and ${({theme: {device}}) => device.sm} {
		 grid-row: 2;
	 }
`;


export const ComponentImageWrapper = styled.div`
	display: grid;
	position: relative;
	justify-items: ${props => props.isReversed === true ? 'left' : 'right'};
	align-items: ${props => props.centeredVertically === true ? 'center' : 'start'};
	
	@media all and ${({theme: {device}}) => device.md} {
		align-items: center;
		justify-items: center;
	}
`;

export const ComponentImage = styled.img`
	width: 90%;

	@media all and ${({theme: {device}}) => device.md} {
		width: 100%;
	}
	
	@media all and ${({theme: {device}}) => device.sm} {
		width: 55%;
		grid-row: 1;
	}
`;
