import React from 'react';
import {DetailItemContainer, DetailItemIcon, DetailItemText} from "./styles";

const JobDetailItem = ({text, type}) => {
  return (
	  <DetailItemContainer>
		  <DetailItemIcon data-type={type} />
		  <DetailItemText>{text}</DetailItemText>
	  </DetailItemContainer>
  );
};

export default JobDetailItem;
