import React from 'react';
import styled from "styled-components";
import {Container} from "@material-ui/core";

export const StyledContainer = styled(Container)`
	@media all and ${({theme: {device}}) => device.lg} {
		padding: 0 !important;
	}

	@media all and ${({theme: {device}}) => device.md} {
		padding: 0 24px !important;
	}
`;


const ContentSizeLimiter = ({children, ...props}) => {
  return (
    <StyledContainer {...props}>
	    {children}
    </StyledContainer>
  );
};

export default ContentSizeLimiter;
