import {useMemo} from "react";
import {jobs} from "../useJobs";

function useJobsCategoryMap(){
	return useMemo(() => {
		const jobsByCategory = {};

		jobs.forEach((job) => {
			if (jobsByCategory.hasOwnProperty(job.category) === false) {
				jobsByCategory[job.category] = [];
			}

			jobsByCategory[job.category].push(job);
		});

		return jobsByCategory;
	}, [])
}


export default function useJobsGroupedByCategory(){
	const jobsCategoryMap = useJobsCategoryMap();
	return useMemo(() =>
		Object.keys(jobsCategoryMap).map(
			key => Object.assign({}, {category: key, items: jobsCategoryMap[key]})
		),
[jobsCategoryMap]
	);
}



