import validate from 'validate.js';

export default class DataValidation {
  static validate(data, constraints, options = {}) {
    return validate(data, constraints, options);
  }

  static validateSingleValue(data, constraints, options) {
    const composedData = {myValue: data};
    const composedConstraints = {
      myValue: constraints,
    };
    const validationErrors = this.validate(
      composedData,
      composedConstraints,
      options,
    );

    return typeof validationErrors === 'undefined'
      ? validationErrors
      : validationErrors.myValue;
  }
}

const extractFirstValidationError = (validationResult) => {
  return typeof validationResult !== 'undefined'
    ? validationResult.shift()
    : null;
};

export const validateSingle = (data, constraints) =>
  extractFirstValidationError(
    DataValidation.validateSingleValue(data, constraints),
  );
