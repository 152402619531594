import {store} from 'react-notifications-component';

export function createNotification(title, message, type, ...props){
	store.addNotification({
		title: title,
		message: message,
		type: type,
		insert: "top",
		container: "top-right",
		animationIn: ["animate__animated", "animate__flipInY"],
		animationOut: ["animate__animated", "animate__fadeOut"],
		dismiss: {
			duration: 5000,
			onScreen: true,
		},
		...props,
	})
}

export function createInformationalNotification(title, message, ...props){
	createNotification(title, message, 'default', ...props);
}

export function createErrorNotification(title, message, ...props){
	createNotification(title, message, 'danger', ...props);
}

export function createWarningNotification(title, message, ...props){
	createNotification(title, message, 'warning', ...props);
}
