import styled from 'styled-components';

export const JobApplicationFormContainer = styled.div`
  display: grid;
	grid-row-gap: 20px;
`;

export const FormTitleText = styled.div`
  font-size: 30px;
	color: #1565D8;
`;

export const FormContentContainer = styled.div`
  display: grid;
	grid-row-gap: 20px;
`;
