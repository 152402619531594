import {useMemo} from "react";

import Environment from "../../Environment";
import DefaultFooter from "./components/Footers/DefaultFooter";

const headersMap = {
	default: DefaultFooter,
}

export default function usePageFooter(){
	return useMemo(() => {
		return headersMap.hasOwnProperty(Environment.region)
			? headersMap[Environment.region]
			: headersMap['default'];
	}, []);
}
