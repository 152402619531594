import React from 'react';
import StaticPageContainerWithHeaderSpaceFix from "../../../components/Layout/StaticPageContainerWithHeaderSpaceFix";
import ContentSizeLimiter from "../../../components/UI/Containers/ContentSizeLimiter";

import HeroSection from "./components/HeroSection";
import JobOpeningsSection from "./components/JobOpeningsSection";
import useJobsGroupedByCategory from "./useJobsItems";

import {PageContainer, JobOpeningsSectionWrapper} from "./styles";

const CareerScreen = () => {
  const jobsGroupedByCategory = useJobsGroupedByCategory();

  return (
    <StaticPageContainerWithHeaderSpaceFix>
      <PageContainer>
        <HeroSection />
        <JobOpeningsSectionWrapper>
          <ContentSizeLimiter>
            <JobOpeningsSection jobsGroupedByCategory={jobsGroupedByCategory} />
          </ContentSizeLimiter>
        </JobOpeningsSectionWrapper>
      </PageContainer>
    </StaticPageContainerWithHeaderSpaceFix>
    );
};

export default CareerScreen;
