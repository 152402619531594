import {useContext} from 'react';
import {LocalisationContext} from "../../Providers/SharedProviders/LocalisationProvider";

export const useNotEmptyValidationRule = () => {
  const {translations} = useContext(LocalisationContext);

  return {
    presence: {
      allowEmpty: false,
      message: `^${translations.common.errors.requiredFieldError}`,
    },
  };
};
