import {useContext} from 'react';
import {LocalisationContext} from "../../Providers/SharedProviders/LocalisationProvider";

export const useEmailValidator = () => {
  const {translations} = useContext(LocalisationContext);

  return {
    email: {
      message: `^${translations.common.errors.emailNotValidError}`,
    },
  };
};
