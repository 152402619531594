import styled from "styled-components";
import jobTypeIcon from './../../assets/clock-icon.png';
import jobLocationIcon from './../../assets/location-icon.png';
import expirationDateIcon from './../../assets/date-icon.png';

export const DetailItemContainer = styled.div`
  display: flex;
	column-gap: 10px;
	align-items: center;
`;

export const DetailItemIcon = styled.div`
	width: 20px;
	height: 20px;
	
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	
  &[data-type="job-type"]{
	  background-image: url(${jobTypeIcon});
  }

  &[data-type="job-location"]{
    background-image: url(${jobLocationIcon});
  }

  &[data-type="job-expiration-date"]{
    background-image: url(${expirationDateIcon});
  }
`;


export const DetailItemText = styled.div`
    
`;
