import styled from "styled-components";
import {TitleText} from "../ContentForSectionWithSideImage/styles";

export const SectionContainer = styled.div`
 ${TitleText} {
	 font-size: 42px;
	 line-height: 54px;
 }
`;

export const ButtonsWrapper = styled.div`
 margin-top: 15px;
 display: flex;
 column-gap: 15px;
`;
