import React from 'react';
import ContentSizeLimiter from "../../components/UI/Containers/ContentSizeLimiter";
import StaticPageContainerWithHeaderSpaceFix from "../../components/Layout/StaticPageContainerWithHeaderSpaceFix";
import ContactFormSection from "../../components/Sections/ContactFormSection";

import HeroSection from "./components/HeroSection";
import PartnersSection from "./components/PartnersSection";
import UpgradeSection from "./components/UpgradeSection";
import {ContactFormContainer, SectionsWrapper} from "./styles";

const TechnologyScreen = () => {
  return (
    <StaticPageContainerWithHeaderSpaceFix>
      <HeroSection />

      <SectionsWrapper>
        <UpgradeSection />
        <ContentSizeLimiter>
          <PartnersSection />
        </ContentSizeLimiter>
      </SectionsWrapper>

      <ContactFormContainer left={25} right={85}>
        <ContactFormSection />
      </ContactFormContainer>
    </StaticPageContainerWithHeaderSpaceFix>
    );
};

export default TechnologyScreen;
