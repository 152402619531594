import React, {useContext} from 'react';
import {LocalisationContext} from "../../../../library/Providers/SharedProviders/LocalisationProvider";

import {
	ContentWrapper,
	ItemsWrapper,
	ItemContainer,
	ItemIcon,
	ItemTitle,
	ItemDescription,
	SectionContainer,
	SectionTitle,
} from "./styles";

import useItems from "./useItems";

const WhatWeOffer = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.staticScreens.partners.whatWeOffer;
	const items = useItems();

  return (
    <SectionContainer>
	    <ContentWrapper>
		    <SectionTitle>{translationsObject.title}</SectionTitle>
		    <ItemsWrapper>
			    {items.map((item, index) =>
				    <ItemContainer key={index}>
					    <ItemIcon icon={item.icon} />
					    <ItemTitle>{item.title}</ItemTitle>
					    <ItemDescription>{item.description}</ItemDescription>
				    </ItemContainer>
			    )}
		    </ItemsWrapper>
	    </ContentWrapper>
    </SectionContainer>
  );
};

export default WhatWeOffer;
