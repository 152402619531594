import React, {useContext} from 'react';

import {LocalisationContext} from "../../../../../../../Providers/SharedProviders/LocalisationProvider";
import DefaultButton from "../../../../../../../../components/UI/Buttons/DefaultButton";

import MenuItem from "./components/MenuItem";
import {MenuItemsWrapper, MenuSectionContainer} from "./styles";
import Environment from "../../../../../../../Environment";

const MenuSection = ({items}) => {
	const {translations} = useContext(LocalisationContext);
  return (
    <MenuSectionContainer>
	    <MenuItemsWrapper numberOfItems={items.length}>
		    {items.map((item, index) =>
			    <MenuItem key={index} {...item} />
		    )}
	    </MenuItemsWrapper>
		<DefaultButton
			color={'#2051E2'}
			textColor={'white'}
			to={{pathname: Environment.LoginURL}}
			target={'_parent'}
		>
			{translations.sections.header.loginButton}
		</DefaultButton>
    </MenuSectionContainer>
  );
};

export default MenuSection;
