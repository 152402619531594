import styled from 'styled-components';

export const ItemsWrapper = styled.div`
  display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 50px;
	padding: 0 50px;
	
	@media all and ${({theme: {device}}) => device.sm} {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 70px;
	}
`;

const iconSize = 60;
export const ItemContainer = styled.div`
  display: grid;
	grid-template-rows: ${iconSize}px max-content 1fr;
	grid-row-gap: 30px;
	justify-items: center;
`;

export const ItemIcon = styled.div`
	width: ${iconSize}px;
	background-image: url(${props => props.icon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
`;

export const ItemTitle = styled.div`
  text-align: center;
	font-size: 25px;
	line-height: 30px;
	font-weight: bold;
`;

export const ItemDescription = styled.div`
  text-align: center;
	color: #606060;
	font-size: 17px;
	line-height: 33px;
	
	max-width: 400px;
`;

