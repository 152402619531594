import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

const getRoundness = (roundness) => {
	const roundnessSizes = {xs: 4, sm: 8, md: 16, lg: 24, xl: 32}
	return roundnessSizes.hasOwnProperty(roundness) ? roundnessSizes[roundness] : roundness;
}

const getPadding = (props) => {
	const baseVerticalPadding = 10;
	const baseHorizontalPadding = 30;

	const topPadding = props.topPadding || props.verticalPadding || baseVerticalPadding;
	const bottomPadding = props.bottomPadding || props.verticalPadding || baseVerticalPadding;
	const leftPadding = props.leftPadding || props.horizontalPadding || baseHorizontalPadding;
	const rightPadding = props.rightPadding || props.horizontalPadding || baseHorizontalPadding;

	return `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px`;
}

const getButtonColors = (props) => {
	const defaultBackgroundColor = '#fff';
	const defaultTextColor = '#000';

	let computedBackgroundColor, computedTextColor, computedBorderColor;

	switch(props.mode){
		case 'text':
			computedBackgroundColor = 'transparent';
			computedTextColor = props.color || defaultBackgroundColor;
			computedBorderColor = computedBackgroundColor;
			break;
		case 'outlined':
			computedBackgroundColor = 'transparent';
			computedTextColor = props.color || defaultBackgroundColor;
			computedBorderColor = props.borderColor || computedTextColor;
			break;
		case 'contained':
		default:
			computedBackgroundColor = props.color || defaultBackgroundColor;
			computedTextColor = props.textColor || defaultTextColor;
			computedBorderColor = props.borderColor || computedBackgroundColor;
	}

	return {
		text: computedTextColor,
		border: computedBorderColor,
		background: computedBackgroundColor,
	}
}

export const BaseButton = styled(Link)`
	position: relative;
	display: inline-block;
	text-decoration: none;
	
	padding: ${(props) => getPadding(props)};

	border-width: 1px;
	border-style: solid;
	border-radius: ${props => props.roundness !== undefined ? getRoundness(props.roundness) : getRoundness('xs')}px;
	border-color: ${props => getButtonColors(props).border};

	background-color: ${props => getButtonColors(props).background};
	color: ${props => getButtonColors(props).text};
	
	font-size: ${props => props.fontSize ? props.fontSize : '14'}px;
	font-weight: 400;
	text-align: center;

	&:hover {
		box-shadow: 0 5px 10px rgba(112, 112, 112, 0.26);

		&::after {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			content: " ";
			background-color: rgba(255, 255, 255, 0.05);
			border-radius: ${props => props.roundness ? getRoundness(props.roundness) : getRoundness('xs')}px;
		}
	}
`;

const DefaultButton = ({children, ...props}) => {
  return (
    <BaseButton {...props}>
	    {children}
    </BaseButton>
  );
};

export default DefaultButton;
