import styled from "styled-components";
import sectionBackgroundImage from './assets/section-image.png';

export const SectionContainer = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: 30px;
  background-image: url(${sectionBackgroundImage});
  background-size: cover;
  background-position: center;

  padding: 170px 0;

  @media all and ${({theme: {device}}) => device.sm} {
    background-position: center right -400px;
  }

  @media all and ${({theme: {device}}) => device.sm} {
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  display: grid;
	grid-row-gap: 30px;
	width: 50%;
	
	@media all and ${({theme: {device}}) => device.sm} {
		width: 100%;
	}
`;

export const TitlesWrapper = styled.div`
  display: grid;
	grid-row-gap: 10px;
`;

export const SectionSubTitle = styled.div`
	color: #1565D8;
  font-size: 21px;
	line-height: calc(34 / 21);
`;

export const SectionTitle = styled.div`
  font-size: 46px;
	line-height: calc(59 / 46);
	color: white;
	
	@media all and ${({theme: {device}}) => device.md} {
		font-size: 35px;
	}
	
  @media all and ${({theme: {device}}) => device.xs} {
    font-size: 29px;
  }
`;

export const SectionDescription = styled.div`
  font-size: 20px;
  line-height: 34px;
	color: white;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
	column-gap: 30px;
`;


