import {useMemo} from "react";

import Environment from "../../Environment";
import DefaultHeader from "./components/Headers/DefaultHeader";

const headersMap = {
	default: DefaultHeader,
}

export default function usePageHeader(){
	return useMemo(() => {
		return headersMap.hasOwnProperty(Environment.region)
			? headersMap[Environment.region]
			: headersMap['default'];
	}, []);
}
