import React, {useContext, useState} from 'react';
import {LocalisationContext} from "../../../library/Providers/SharedProviders/LocalisationProvider";
import ContentSizeLimiter from "../../UI/Containers/ContentSizeLimiter";
import Environment from "../../../library/Environment";
import ApiService from "../../../module/Api/ApiService";

import {
	SectionContainer,
	LeftContentContainer,
	ContentWrapper,
	RightContentContainer,
	SectionSubTitle,
	SectionTitle,
	SectionDescription,
	ContactInformationWrapper,
	ContactInformationItem,
	ContactInformationItemIcon
} from "./styles";

import DefaultButton from "../../UI/Buttons/DefaultButton";
import FormTextField from "./components/FormTextField";

import waveIcon from './assets/wave-icon.png';
import emailIcon from './assets/email-icon.png';
import addressIcon from './assets/address-icon.png';
import {useContactFormDataValidation} from "./validation";
import {createInformationalNotification} from "../../../library/Notification";

const ContactFormSection = () => {
	const {translations} = useContext(LocalisationContext);
	const translationsObject = translations.sections.contactFormSection;

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [formErrors, setFormErrors] = useState({});

	const formValidation = useContactFormDataValidation({fullName: name, email, subject, message});

	const handleButtonAction = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		const page = window.location.href;

		if(formValidation.hasError === true) {
			setFormErrors(formValidation);
			console.log(formValidation);
			return false;
		} else {
			setFormErrors({});
		}

		const model = {name, email, subject, message, page};
		ApiService.sendContactMessage(model)
			.then(() => {
				setName('');
				setEmail('');
				setSubject('');
				setMessage('');
				createInformationalNotification(null, translationsObject.messageSentText)
			})
			.catch(() => createInformationalNotification(null, translationsObject.messageSendErrorText));
	}

  return (
    <SectionContainer>
	    <ContentSizeLimiter>
		    <ContentWrapper>
			    <LeftContentContainer>
				    <div>
					    <SectionSubTitle><span />{translationsObject.subTitle}<img alt={'wave'} src={waveIcon}/></SectionSubTitle>
					    <SectionTitle>{translationsObject.title}</SectionTitle>
					    <SectionDescription>{translationsObject.description}</SectionDescription>
				    </div>
				    <ContactInformationWrapper>
					    <ContactInformationItem>
						    <ContactInformationItemIcon icon={emailIcon} />
						    {Environment.contact.email}
					    </ContactInformationItem>
					    <ContactInformationItem>
						    <ContactInformationItemIcon icon={addressIcon} />
						    {Environment.contact.address}
					    </ContactInformationItem>
				    </ContactInformationWrapper>
			    </LeftContentContainer>
			    <RightContentContainer>
				    <FormTextField
					    value={name}
					    helperText={formErrors.fullNameError}
					    onChange={(e) => setName(e.target.value)}
					    variant={'filled'}
					    label={translationsObject.namePlaceholder}
				    />
				    <FormTextField
					    value={email}
					    helperText={formErrors.emailError}
					    onChange={(e) => setEmail(e.target.value)}
					    variant={'filled'}
					    label={translationsObject.emailPlaceholder}
				    />
				    <FormTextField
					    value={subject}
					    helperText={formErrors.subjectError}
					    onChange={(e) => setSubject(e.target.value)}
					    variant={'filled'}
					    label={translationsObject.subjectPlaceholder}
				    />
				    <FormTextField
					    value={message}
					    helperText={formErrors.messageError}
					    onChange={(e) => setMessage(e.target.value)}
					    variant={'filled'}
					    multiline rows={3}
					    label={translationsObject.messagePlaceholder}
				    />
				    <div>
					    <DefaultButton
						    color={'#5468E7'}
						    textColor={'white'}
						    fullWidth={false}
						    onClick={handleButtonAction}
						    to={''}
					    >
						    {translationsObject.buttonText}
					    </DefaultButton>
				    </div>
			    </RightContentContainer>
		    </ContentWrapper>
	    </ContentSizeLimiter>
    </SectionContainer>
  );
};

export default ContactFormSection;
