import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useMediaQuery, useTheme} from "@material-ui/core";
import {Menu} from "@material-ui/icons";

import {MenuCloseButton, MenuDrawerComponent, MenuSectionContentContainer} from "./styles";

const ResponsiveMenu = ({children, breakpoint}) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const toggleDrawer = useCallback(() => setIsDrawerOpen(!isDrawerOpen), [isDrawerOpen]);

	const theme = useTheme();
	const shouldRenderResponsiveVersion = useMediaQuery(theme.breakpoints.down(breakpoint));

	const MenuSectionWrapperComponent = shouldRenderResponsiveVersion ? MenuDrawerComponent : React.Fragment;
	const MenuSectionContentContainerComponent = shouldRenderResponsiveVersion ? MenuSectionContentContainer : React.Fragment;

	const MenuSectionWrapperProps = useMemo(() => {
		if(shouldRenderResponsiveVersion === false) {
			return {};
		}

		return {
			anchor: 'left',
			onClose: toggleDrawer,
			onOpen: toggleDrawer,
			open: isDrawerOpen,
		}
	}, [shouldRenderResponsiveVersion, isDrawerOpen, toggleDrawer]);

	/**
	 * Close the menu automatically when resizing the page past the breakpoint
	 */
	useEffect(() => {
		shouldRenderResponsiveVersion === false && setIsDrawerOpen(false);
	}, [shouldRenderResponsiveVersion]);

  return (
    <React.Fragment>
	    {shouldRenderResponsiveVersion === true && (
		    <Menu onClick={toggleDrawer} />
	    )}

	    <MenuSectionWrapperComponent {...MenuSectionWrapperProps}>
		    {shouldRenderResponsiveVersion === true && (
			    <MenuCloseButton onClick={toggleDrawer} />
		    )}
		    <MenuSectionContentContainerComponent>
			    {children}
		    </MenuSectionContentContainerComponent>
	    </MenuSectionWrapperComponent>
    </React.Fragment>
  );
};

export default ResponsiveMenu;
