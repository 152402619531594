import styled from "styled-components";

export const SectionContainer = styled.div`
	position: relative;
`;

export const ContentContainer = styled.div`
	max-width: 50%;

	@media all and ${({theme: {device}}) => device.sm} {
		max-width: initial;
		padding: 0 40px;
	}

  @media all and ${({theme: {device}}) => device.xs} {
    padding: 0;
  }
`;

export const ContentItemsWrapper = styled.div`
	display: grid;
	grid-row-gap: 40px;
	
	@media all and ${({theme: {device}}) => device.sm} {
    grid-row-gap: 80px;
  }
`;

export const TextAndButtonsWrapper = styled.div`
  display: grid;
  grid-row-gap: 40px;
`;

export const TitleText = styled.div`
	font-size: 52px;
	font-weight: 500;
`;

export const DescriptionText = styled.div`
	font-size: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
	column-gap: 20px;
`;

export const StatWrapper = styled.div`
  display: grid;
	grid-template-columns: repeat(3, max-content);
	justify-content: space-between;

  @media all and ${({theme: {device}}) => device.xs} {
    grid-template-columns: repeat(1, max-content);
    grid-row-gap: 40px;
  }
`;

export const StatItemContainer = styled.div`
  display: grid;
	grid-row-gap: 5px;

  @media all and ${({theme: {device}}) => device.xs} {
	  grid-template-columns: repeat(1, fr);
  }
`;

export const StatItemValue = styled.div`
  font-size: 50px;
	
	span {
		color: #F2B604;
	}
`;

export const StatItemText = styled.div`
	font-size: 18px;

  @media all and ${({theme: {device}}) => device.sm} {
    font-size: 16px;
  }
`;
