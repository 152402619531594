import React from 'react';
import styled from "styled-components";

export const ContentContainer = styled.div`
	padding-top: 80px;
`;

const StaticPageContainerWithHeaderSpaceFix = ({children}) => {
  return (
    <ContentContainer>{children}</ContentContainer>
  );
};

export default StaticPageContainerWithHeaderSpaceFix;
