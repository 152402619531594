import styled from "styled-components";
import sectionImage from './assets/hero-section-image.png';

export const SectionContainer = styled.div`
	display: grid;
	grid-template-columns: 2fr 3fr;
	grid-column-gap: 50px;
	padding: 50px 0;
	color: white;
	
	@media all and ${({theme: {device}}) => device.sm} {
    grid-template-columns: 1fr;
		justify-items: center;
  }
`;

export const ImageContainer = styled.div`
  background-image: url(${sectionImage});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center left;
	
	@media all and ${({theme: {device}}) => device.sm} {
		display: none;
	}
`;

export const ContentWrapper = styled.div`
  display: grid;
	grid-row-gap: 30px;
	grid-template-rows: max-content max-content;
	align-content: center;
	align-items: center;
	
	padding: 120px 0;
	
	@media all and ${({theme: {device}}) => device.sm} {
    padding: 120px 0;
  }
`;

export const SectionTitle = styled.div`
  font-size: 46px;
	line-height: calc(59 / 46);

  @media all and ${({theme: {device}}) => device.md} {
		font-size: 43px;
    max-width: 650px;
  }
	
	@media all and ${({theme: {device}}) => device.sm} {
    font-size: 46px;
		max-width: 700px;
  }
	
  @media all and ${({theme: {device}}) => device.xs} {
    font-size: 39px;
  }
`;

export const SectionDescription = styled.div`
	font-size: 20px;
	line-height: 34px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
	column-gap: 30px;
`;


