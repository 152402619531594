import styled from "styled-components";
import jobTypeIcon from './../../../../assets/clock-image.png';
import jobLocationIcon from './../../../../assets/location-image.png';
import expirationDateIcon from './../../../../assets/calendar-image.png';

export const DetailItemContainer = styled.div`
  display: flex;
	column-gap: 10px;
	align-items: center;
`;

export const DetailItemIcon = styled.div`
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	
  &[data-type="job-type"]{
	  background-image: url(${jobTypeIcon});
	  width: 22.48px;
	  height: 22.48px;
  }

  &[data-type="job-location"]{
    background-image: url(${jobLocationIcon});
    width: 17.3px;
    height: 23.66px;
  }

  &[data-type="job-expiration-date"]{
    background-image: url(${expirationDateIcon});
    width: 20px;
    height: 20px;
  }
`;


export const DetailItemText = styled.div`
    
`;
