import styled from "styled-components";
import ContainerWithSkewBackground from "../../components/UI/Containers/ContainerWithSkewBackground";

export const ScreenContainer = styled.div``;

export const SectionsWrapper = styled.div`
  display: grid;
	grid-row-gap: 105px;
	padding: 105px 0;
`;

export const SectionTitleContainer = styled.div`
  display: grid;
  grid-row-gap: 30px;
	justify-items: center;
`;

export const SectionTitleText = styled.div`
	font-size: 58px;
	line-height: 1.3;
	text-align: center;
`;

export const SectionIcon = styled.div`
	width: 72px;
	height: 72px;
	border-radius: 20px;
	
	background-image: url(${props => props.icon});
	background-size: 24px;
	background-repeat: no-repeat;
	background-position: center;
`;

export const ContactFormContainer = styled(ContainerWithSkewBackground)`
	padding: 70px;
	background-color: #F2F3F4;
	
	@media all and ${({theme: {device}}) => device.xs} {
		padding-right: 20px;
		padding-left: 20px;
	}
	
	&::after {
		background-color: #00113B;
	}
`;
