import {lazy} from "react";
import Environment from "../Environment";
import {APP_REGION_CY, DEVELOPMENT, PRODUCTION, STAGING} from "../Environment/EnvironmentConstants";

import {
	ABOUT_US_ROUTE,
	PARTNERS_ROUTE,
	CONTACT_US_ROUTE,
	FAQ_ROUTE,
	HOMEPAGE_ROUTE,
	LOGO_GUIDELINES_ROUTE,
	NOT_FOUND_ROUTE,
	PRIVACY_POLICY_ROUTE,
	SECURITY_ROUTE,
	ECOMMERCE_ROUTE,
	TOS_ROUTE,
	COOKIE_POLICY_ROUTE,
	CAREER_ROUTE,
	CAREER_DETAIL_ROUTE, TECHNOLOGY_ROUTE, POS_ROUTE, PRIVACY_POLICY_JOB_APPLICANTS_ROUTE
} from "./NavigationConstants";

import PartnersScreen from "../../static-screens/PartnersScreen";
import AboutUsScreen from "../../static-screens/AboutUsScreen";
import EcommerceScreen from "../../static-screens/EcommerceScreen";
import LogoGuidelinesScreen from "../../static-screens/LogoGuidelinesScreen";
import CareerScreen from "../../static-screens/Career/CareerScreen";
import CareerDetailsScreen from "../../static-screens/Career/CareerDetailsScreen";
import TechnologyScreen from "../../static-screens/TechnologyScreen";
import PosScreen from "../../static-screens/PosScreen";
import ComingSoonScreen from "../../static-screens/ComingSoonScreen";

const HomepageScreen = lazy(() => import('../../static-screens/HomePageScreen'));
const ContactScreen =  lazy(() => import ("../../module/StaticPages/screens/ContactScreen"));
const SecurityScreen = lazy(() => import ("../../static-screens/SecurityScreen"));
const NotFound = lazy(() => import("../../module/StaticPages/screens/NotFoundScreen"));

/**
 * Legal Screens
 */
const FAQScreen = lazy(() => import ("../../static-screens/FAQScreen"));
const TermsOfServiceScreen =  lazy(() => import ("../../static-screens/LegalScreens/TermsOfServiceScreen"));
const CookiePolicyScreen =  lazy(() => import ("../../static-screens/LegalScreens/CookiePolicyScreen"));
const PrivacyPolicyScreen =  lazy(() => import ("../../static-screens/LegalScreens/PrivacyPolicyScreen"));
const PrivacyPolicyJobApplicationScreen =  lazy(() => import ("../../static-screens/LegalScreens/PrivacyPolicyForJobApplicationts"));

const DEFAULT_MAPPING = {
	[HOMEPAGE_ROUTE]: HomepageScreen,
	[ABOUT_US_ROUTE]: AboutUsScreen,
	[CONTACT_US_ROUTE]: ContactScreen,
	[FAQ_ROUTE]: FAQScreen,
	[SECURITY_ROUTE]: SecurityScreen,
	[LOGO_GUIDELINES_ROUTE]: LogoGuidelinesScreen,

	[ECOMMERCE_ROUTE]: EcommerceScreen,
	[PARTNERS_ROUTE]: PartnersScreen,
	[CAREER_ROUTE]: CareerScreen,
	[CAREER_DETAIL_ROUTE]: CareerDetailsScreen,
	[TECHNOLOGY_ROUTE]: TechnologyScreen,
	// [POS_ROUTE]: PosScreen,

	[TOS_ROUTE]: TermsOfServiceScreen,
	[COOKIE_POLICY_ROUTE]: CookiePolicyScreen,
	[PRIVACY_POLICY_ROUTE]: PrivacyPolicyScreen,
	[PRIVACY_POLICY_JOB_APPLICANTS_ROUTE]: PrivacyPolicyJobApplicationScreen,
	[NOT_FOUND_ROUTE]: NotFound,
}

const DEVELOPMENT_OVERRIDES = {};
const STAGING_OVERRIDES = {};
const PRODUCTION_OVERRIDES = Environment.APP_REGION === APP_REGION_CY ? {
	[HOMEPAGE_ROUTE]: ComingSoonScreen
} : {};

const OVERRIDE_BY_ENVIRONMENT_TYPE = {
	[DEVELOPMENT]: DEVELOPMENT_OVERRIDES,
	[STAGING]: STAGING_OVERRIDES,
	[PRODUCTION]: PRODUCTION_OVERRIDES,
}

export function getRouteMappingsToScreen(){
	const environmentType = Environment.environmentType;
	const overrideObject = OVERRIDE_BY_ENVIRONMENT_TYPE[environmentType];

	let routeMappings = {...DEFAULT_MAPPING};
	Object.keys(overrideObject).forEach((routeName) => {
		if(DEFAULT_MAPPING.hasOwnProperty(routeName)){
			routeMappings[routeName] = overrideObject[routeName];
		}
	})

	return routeMappings;
}
