import React from 'react';
import {makeStyles, TextField} from "@material-ui/core";

const useStyles = makeStyles({
	root: {
		backgroundColor: '#fff',
		borderRadius: 4,
		borderColor: '#EAECED',
		borderWidth: 1,
		borderStyle: 'solid',
	},
	error: {
		marginLeft: 0,
	},
	underline: {
		"&": {
			color: '#000',
			fontSize: 18
		},
		"&&:before": {
			borderBottom: "none"
		},
		"&&:after": {
			borderBottom: "none"
		}
	}
});

const useHelperStyles = makeStyles({
	root: {
		marginLeft: 0
	},
});

const FormTextField = ({...props}) => {
	const classes = useStyles();
	const helperClasses = useHelperStyles();

	return (
	  <TextField variant={'filled'} FormHelperTextProps={{classes: helperClasses}} InputProps={{ classes }} {...props} />
  );
};

export default FormTextField;
